import React from "react";
import Header from "./components/Header/Header";

export default function LayoultDefault({ children }) {
  return (
    <>
      <Header />
      <div className="container-fluid" style={{ marginTop: "85px" }}>
        {children}
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import * as PrestAPI from "../../../service/api/PrestAPI";
import { toast } from "react-hot-toast";
import "./styles.css";

function ComponentItem({ item, modo, ocultar, onClosed, getCatComplementos }) {
  const [loading, setLoading] = useState(false);

  const [complemento, setComplemento] = useState({
    nome_complemento: "",
    descricao_complemento: "",
    vr_adicional: 0.0,
    cod_externo: "",
    sequencia: "",
    status_complemento: 1,
    id_categoria_complemento: "",
    id_complemento: "",
  });

  useEffect(() => {
    if (modo === "editar") {
      setComplemento(item);
    } else if (modo === "novo") {
      setComplemento((prevComplemento) => ({
        ...prevComplemento,
        id_categoria_complemento: item.id_categoria_complemento,
        id_complemento: item.id_complemento,
      }));
    }
  }, [modo, item]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setComplemento((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    try {
      if (modo === "novo") {
        await saveComplementItem(complemento);
      }
      if (modo === "editar") {
        await editComplementItem(complemento);
      }

      getCatComplementos();
    } catch (error) {
      const errorMsg =
        error.response.data.message || "Erro ao processar a solicitação.";
      toast.error(errorMsg);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const saveComplementItem = async (complemento) => {
    const item = {
      ...complemento,
      status_complemento: complemento.status_complemento ? 1 : 0,
      id_categoria_complemento: Number.parseInt(
        complemento.id_categoria_complemento,
        10
      ),
      id_complemento: Number.parseInt(complemento.id_complemento, 10),
      vr_adicional: parseFloat(complemento.vr_adicional) || 0.0,
      sequencia: Number.parseInt(complemento.sequencia, 10),
    };

    try {
      const response = await PrestAPI.add("ProdutoComplementoItem", item);
      if (response.status === 200) {
        toast.success("Complemento adicionado com sucesso!");
        onClosed();
      }
    } catch (error) {
      const errorMsg =
        error.response.data.message || "Erro ao adicionar complemento.";
      toast.error(errorMsg);
    }
  };

  const editComplementItem = async (complemento) => {
    const item = {
      ...complemento,
      status_complemento: complemento.status_complemento ? 1 : 0,
      id_categoria_complemento: Number.parseInt(
        complemento.id_categoria_complemento,
        10
      ),
      id_complemento: Number.parseInt(complemento.id_complemento, 10),
      vr_adicional: parseFloat(complemento.vr_adicional),
      sequencia: Number.parseInt(complemento.sequencia, 10),
    };

    try {
      const response = await PrestAPI.update(
        "ProdutoComplementoItem",
        `?idComplemento=${item.id_complemento}`,
        item
      );
      if (response.status === 200) {
        toast.success("Complemento salvo com sucesso!");
        onClosed();
      }
    } catch (error) {
      const errorMsg =
        error.response.data.message || "Erro ao salvar complemento.";
      toast.error(errorMsg);
    }
  };

  const handleExcluir = async (complemento) => {
    if (modo !== "editar") {
      return;
    }
    try {
      const response = await PrestAPI.del(
        "ProdutoComplementoItem",
        `?idCatComplemento=${complemento.id_categoria_complemento}&idComplemento=${complemento.id_complemento}`
      );
      if (response.status === 200) {
        toast.success("Complemento excluído com sucesso!");
        onClosed();
      }
    } catch (error) {
      const errorMsg =
        error.response.data.message || "Erro ao excluir complemento.";
      toast.error(errorMsg);
    }
  };

  return (
    <div className="container-fluid" onClick={(e) => e.stopPropagation()}>
      <form onSubmit={handleSubmit} className="row g-3 p-2">
        <div className="col-12 fs-4">
          <h5>Complemento:</h5>
        </div>
        <div className="row">
          <div className="col-sm-6 mt-2">
            <label className="form-label">Nome complemento:</label>
            <input
              type="text"
              name="nome_complemento"
              value={complemento.nome_complemento}
              onChange={handleInputChange}
              className="form-control input-sm"
            />
          </div>
          <div className="col-sm-3 mt-2">
            <label className="form-label">Cod Externo:</label>
            <input
              type="text"
              name="cod_externo"
              value={complemento.cod_externo}
              onChange={handleInputChange}
              className="form-control input-sm"
            />
          </div>
          <div className="col-sm-2 mt-2">
            <label className="form-label">Sequência:</label>
            <input
              type="number"
              name="sequencia"
              value={complemento.sequencia}
              onChange={handleInputChange}
              className="form-control input-sm"
            />
          </div>
          <div className="col-sm-1 mt-2">
            <label className="form-label form-check-label">Status:</label>
            <input
              type="checkbox"
              name="status_complemento"
              checked={complemento.status_complemento}
              onChange={handleInputChange}
              className="form-check-input form-control"
              style={{ width: "30px", height: "30px" }}
            />
          </div>
          <div className="col-sm-8 mt-2">
            <label className="form-label">Descrição:</label>
            <input
              type="text"
              name="descricao_complemento"
              value={complemento.descricao_complemento}
              onChange={handleInputChange}
              className="form-control input-sm"
            />
          </div>
          <div className="col-sm-2 mt-2">
            <label className="form-label">Preço:</label>
            <input
              type="text"
              name="vr_adicional"
              value={complemento.vr_adicional}
              onChange={handleInputChange}
              className="form-control input-sm"
            />
          </div>
          <div className="col-sm-3 mb-0 mt-3">
            <button
              className="form-control input-sm btn btn-vk my-auto"
              type="submit"
              disabled={loading}
            >
              {loading ? "Salvando..." : "Salvar"}
            </button>
          </div>
          <div className="col-sm-3 mb-0 mt-3">
            {modo === "editar" && (
              <button
                className="form-control input-sm btn btn-outline-vk my-auto"
                type="button"
                onClick={() => handleExcluir(complemento)}
              >
                Excluir
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

export default ComponentItem;

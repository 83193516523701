import React, { useState } from "react";
import { Pencil, Trash3 } from "react-bootstrap-icons";

const tipoVendaPlataforma = [
  "anotejá subdominio",
  "anotejá multiloja subdominio",
  "anotejá dominio cliente",
  "anotejá multiloja dominio cliente",
];

const tipoExibicaoProduto = [
  "desativar carrinho e modal o botão adicionar",
  "exibir preço e habilitar carrinho somente cliente logado",
  "não exibir preço, e habilitar carrinho somente cliente logado",
];

const Planos = () => {
  const [planoVempraka, setPlanoVempraka] = useState(0);

  return (
    <section>
      <h4>Planos</h4>
      <form className="row g-3">
        <div className="col-lg-6">
          <label className="form-label">Nome do plano</label>
          <input type="text" className="form-control" />
        </div>
        <div className="col-lg-3">
          <label className="form-label">Recorrencia do plano</label>
          <select className="form-select">
            <option>mensal</option>
            <option>trimestral</option>
            <option>anual</option>
          </select>
        </div>
        <div className="row mt-3">
          <div
            className="my-3 mx-1 p-3 border border-2 rounded position-relative col-lg-6"
            style={{ maxWidth: "300px" }}
          >
            <span
              className="position-absolute bg-white px-2"
              style={{ top: "-10px", left: "20px" }}
            >
              Tipos de vendas
            </span>
            <div className="row">
              {[
                "todos",
                "delivery",
                "retirada",
                "encomenda",
                "consumir",
                "cardapio",
              ].map((tipo, index) => (
                <div className="col-6" key={index}>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name={tipo}
                      id={`checkbox-${tipo}`}
                    />
                    <label
                      htmlFor={`checkbox-${tipo}`}
                      className="form-check-label"
                    >
                      {tipo.charAt(0).toUpperCase() + tipo.slice(1)}
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-3">
            <span className="form-label">Plataforma do plano</span>
            <div className="form-check mt-2">
              <label className="form-check-label">Vempraka</label>
              <input
                type="checkbox"
                className="form-check-input"
                style={{ width: "20px", height: "20px" }}
              />
            </div>
            <div className="form-check mt-2">
              <label className="form-check-label">Anotejá</label>
              <input
                type="checkbox"
                className="form-check-input"
                style={{ width: "20px", height: "20px" }}
              />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="col-12">
              <label className="form-label">Valor</label>
              <input type="number" className="form-control" />
            </div>
            <div className="col-12">
              <label className="form-label">Valor % vempraka</label>
              <input type="number" className="form-control" />
            </div>
          </div>
        </div>
      </form>
      <div className="table-responsive mt-4">
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th>Ações</th>
              <th>Nome</th>
              <th>valor</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="d-flex justify-content-evenly">
                <button className="btn btn-outline-light" title="Editar">
                  <Pencil color="#FF5722" />
                </button>

                <button className="btn btn-outline-light" title="Excluir">
                  <Trash3 color="#FF5722" />
                </button>
              </td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default Planos;

import { useState } from "react";
import benefitsService from "../service";

export const useUpdateBenefit = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateBenefit = async (data) => {
    try {
      setLoading(true);
      const response = await benefitsService.updateBenefit(
        data.id_estabelecimento,
        data.uid,
        data
      );
      return response;
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { updateBenefit, loading, error };
};

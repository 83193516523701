import { apiClient } from "../../service/api/PrestAPI";

const getPedidos = async (id_estabelecimento) => {
  return await apiClient.get(`Pedidos?estab=${id_estabelecimento}`);
};
const getPedido = async (id_pedido) => {
  return await apiClient.get("Pedidos", `id_pedido=${id_pedido}`);
};

export default { getPedidos, getPedido };

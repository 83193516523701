import React from "react";

const PedidoButton = ({ label, modo, modoAtual, setModoPedido, cor }) => {
  const isClicked = modoAtual === modo;

  return (
    <div className="w-100 h-100">
      <button
        style={{
          backgroundColor: cor,
          boxShadow: isClicked
            ? "inset 5px 5px 15px rgba(0, 0, 0, 0.6)"
            : "5px 5px 15px rgba(0, 0, 0, 0.3)",
          transform: isClicked ? "translateY(2px)" : "none",
        }}
        className={`btn w-100 py-3 fs-3 text-light ${
          isClicked && "border border-1 border-dark"
        }`}
        onClick={() => setModoPedido(modo)}
      >
        {label}
      </button>
    </div>
  );
};

export default PedidoButton;

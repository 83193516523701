import React from "react";

export default function InfoCards({ pedidosStats }) {
  const {
    totalPedidos = 0,
    totalValor = 0,
    ticketMedio = 0,
    totalCancelados = 0,
  } = pedidosStats || {};

  const cardStyle =
    "rounded-4 card-info text-center d-flex justify-content-center align-items-center text-light";

  return (
    <div className="container-fluid mb-4 row px-4 mx-auto mx-lg-0">
      <div className="row overflow-hidden g-2 align-items-end col-lg-7">
        <div className="col text-center">
          <span className="fs-4 py-2">Quant. pedidos</span>
          <div className={cardStyle} style={{ backgroundColor: "#F75D29FF" }}>
            <span style={{ fontSize: "4rem" }}>{totalPedidos}</span>
          </div>
        </div>
        <div className="col text-center">
          <span className="fs-4 py-2">Total dos pedidos</span>
          <div className={cardStyle} style={{ backgroundColor: "#F75D29FF" }}>
            <span className="fs-1 ">
              R$
              {totalValor.toLocaleString("pt-BR", { minimumFractionDigits: 2 })}
            </span>
          </div>
        </div>
        <div className="col text-center">
          <span className="fs-4 py-2">Ticket Médio</span>
          <div className={cardStyle} style={{ backgroundColor: "#F75D29FF" }}>
            <span className="m-auto fs-1">
              R$
              {ticketMedio.toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
              })}
            </span>
          </div>
        </div>
        <div className="col text-center">
          <span className="fs-4 py-2">Cancelados</span>
          <div className={cardStyle} style={{ backgroundColor: "#F75D29FF" }}>
            <span style={{ fontSize: "4rem" }}>{totalCancelados}</span>
          </div>
        </div>
      </div>
      <div className="row col-lg-5 justify-content-end d-none">
        <div className="d-flex gap-2 justify-content-end">
          <button className="btn btn-vk m-auto">Novo Pedido</button>
          <button className="btn btn-vk m-auto">Abrir Caixa</button>
        </div>
        <div>
          <span>
            Operador:<strong></strong>
          </span>
          <span>
            Nr. Caixa:<strong></strong>
          </span>
        </div>
      </div>
    </div>
  );
}

import { useState, useCallback, useEffect } from "react";
import toast from "react-hot-toast";
import services from "../services";

export const useLoadPedidos = (estabelecimento) => {
  const [pedidos, setPedidos] = useState(null); // Initialize as null to distinguish loading states
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const loadPedidos = useCallback(async () => {
    if (!estabelecimento) return;

    setIsLoading(true);
    setError(null);
    try {
      const res = await services.getPedidos(estabelecimento.id_estabelecimento);
      setPedidos(res.data.pedidos);
    } catch (error) {
      setError("Erro ao carregar pedidos.");
      toast.error("Erro ao carregar pedidos.");
    } finally {
      setIsLoading(false);
    }
  }, [estabelecimento]);

  useEffect(() => {
    loadPedidos();
  }, [loadPedidos]);

  return { pedidos, loadPedidos, isLoading, error };
};

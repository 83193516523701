import React, { Component } from "react";
import PropTypes from "prop-types";
import serializeForm from "form-serialize";
import * as prestAPI from "../../../service/api/PrestAPI";
import { TrashFill } from "react-bootstrap-icons";
import { toast } from "react-hot-toast";

class ModalAgenda extends Component {
  static propTypes = {
    produto: PropTypes.object.isRequired,
    hideModal: PropTypes.func,
    modo: PropTypes.string.isRequired,
    setAgenda: PropTypes.func.isRequired,
    item: PropTypes.object,
    isNovaAgenda: PropTypes.bool,
    complemento: PropTypes.object,
  };

  state = {
    disponibilidade: [
      {
        turno: 1,
        horario: "00:00|00:00",
      },
    ],
    diasSelecionados: {
      dom: false,
      seg: false,
      ter: false,
      qua: false,
      qui: false,
      sex: false,
      sab: false,
    },
    numeroR: 0,
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { produto, modo, complemento, setAgenda, hideModal } = this.props;
    const idProduto = produto.id_produto;
    let { disponibilidade, diasSelecionados } = this.state;

    const form = e.target;
    const data = serializeForm(form, { hash: true });

    disponibilidade = disponibilidade.map((disp, index) => {
      const horaInicio = Array.isArray(data.horaInicio)
        ? data.horaInicio[index]
        : data.horaInicio;
      const horaFim = Array.isArray(data.horaFim)
        ? data.horaFim[index]
        : data.horaFim;
      return {
        ...disp,
        horario: `${horaInicio}|${horaFim}`,
      };
    });

    this.setState({ disponibilidade });

    const dias = Object.keys(diasSelecionados).filter(
      (dia) => diasSelecionados[dia]
    );

    if (dias.length === 0) {
      toast.error("Você precisa selecionar os dias da semana");
      return;
    }

    const dados = {
      agenda: [
        {
          descricao: data.descricao,
          dias: dias.join(""),
          disponibilidade,
        },
      ],
    };

    try {
      if (modo === "produto") {
        await this.salvarAgendaProduto(idProduto, dados, setAgenda);
      } else if (modo === "complemento") {
        await this.salvarAgendaComplemento(complemento, dados, setAgenda);
      }
      toast.success("Agenda salva com sucesso!");
      hideModal();
    } catch (error) {
      console.error("Erro ao salvar agenda:", error);
      toast.error("Erro ao salvar agenda!");
    }
  };

  salvarAgendaProduto = async (idProduto, dados, setAgenda) => {
    const result = await prestAPI.add(
      `Produto?modo=disponibilidade&idProduto=${idProduto}`,
      dados
    );
    if (result.status === 200) {
      const resultProduto = await prestAPI.get(
        `Produto?id_produto=${idProduto}&modo=retaguarda`
      );
      const agenda = resultProduto.produtos[0].agenda;
      setAgenda(agenda);
    }
  };

  salvarAgendaComplemento = async (complemento, dados, setAgenda) => {
    const result = await prestAPI.add(
      `ProdutoComplementoItem?modo=disponibilidade&idComplemento=${complemento.id_complemento}`,
      dados
    );
    if (result.status === 200) {
      const res = await prestAPI.get(
        "ProdutoComplemento",
        `estab=${this.props.produto.id_estabelecimento}&idProduto=${this.props.produto.id_produto}&modo=retaguarda`
      );
      const agenda = this.filtrarAgendaComplemento(
        res.complementos,
        complemento.id_categoria_complemento,
        complemento.id_complemento
      );
      setAgenda(agenda);
    }
  };

  filtrarAgendaComplemento = (
    complementos,
    idCategoriaComplemento,
    idComplemento
  ) => {
    let arrComp = [];
    for (let cat of complementos) {
      if (cat.id_categoria_complemento === idCategoriaComplemento) {
        arrComp = cat.itens.filter(
          (item) => item.id_complemento === idComplemento
        );
        break;
      }
    }
    return arrComp[0].agenda || [];
  };

  handleAdd = () => {
    this.setState((prevState) => ({
      disponibilidade: [
        ...prevState.disponibilidade,
        {
          turno: prevState.disponibilidade.length + 1,
          horario: "00:00|00:00",
        },
      ],
    }));
  };

  handleRemove = (index) => {
    this.setState((prevState) => ({
      disponibilidade: prevState.disponibilidade.filter((_, i) => i !== index),
    }));
  };

  componentDidMount() {
    const { isNovaAgenda, item } = this.props;
    if (!isNovaAgenda) {
      const itDisp = item.disponibilidade;
      const dias = item.dias;
      const numeroR = Math.floor(Math.random() * 1001);

      const disponibilidadeAtualizada = itDisp.map((disp) => {
        const [horaInicio, horaFim] = disp.horario.split("|");
        return { ...disp, horaInicio, horaFim };
      });

      this.setState({
        disponibilidade: disponibilidadeAtualizada,
        numeroR,
      });

      this.verificaDias(dias);
    }
  }

  verificaDias = (dias) => {
    const diasSelecionados = {
      dom: dias.includes("dom"),
      seg: dias.includes("seg"),
      ter: dias.includes("ter"),
      qua: dias.includes("qua"),
      qui: dias.includes("qui"),
      sex: dias.includes("sex"),
      sab: dias.includes("sab"),
    };
    this.setState({ diasSelecionados });
  };

  render() {
    const { disponibilidade, numeroR } = this.state;
    const { item, isNovaAgenda, modo } = this.props;
    return (
      <div
        className="container-fluid p-3 rounded overflow-x-hidden overflow-y-auto z-2"
        style={{
          maxWidth: "1100px",
        }}
      >
        <form onSubmit={this.handleSubmit} className="row g-2 mb-5">
          <div className="col-sm-12 fs-4">
            <b>
              {modo === "produto" && "Nova Agenda (produto)"}
              {modo === "complemento" && "Nova Agenda (complemento)"}
            </b>
          </div>
          <div className="row g-2">
            <div className="col-sm-12">
              <label className="form-label" htmlFor="descricao">
                Descrição:
              </label>
              <input
                type="text"
                name="descricao"
                defaultValue={isNovaAgenda ? "" : item.descricao}
                className="form-control input-md"
                placeholder="Descrição da agenda"
                required
                readOnly={!isNovaAgenda}
              />
            </div>
            <div className="col-sm-6">
              <label className="form-label col w-100">Dias da semana:</label>
              <div className="btn-group" role="group">
                {["dom", "seg", "ter", "qua", "qui", "sex", "sab"].map(
                  (dia, index) => (
                    <label
                      key={index}
                      className={`btn btn-outline-vk fs-5 btn-md ${
                        this.state.diasSelecionados[dia] ? "active" : ""
                      }`}
                      id={`${dia}R${numeroR}`}
                    >
                      <input
                        type="checkbox"
                        className="btn-check"
                        name={dia}
                        id={`${dia}Chk${numeroR}`}
                        autoComplete="off"
                        checked={this.state.diasSelecionados[dia]}
                        onChange={(e) => {
                          const diasSelecionados = {
                            ...this.state.diasSelecionados,
                          };
                          diasSelecionados[dia] = e.target.checked;
                          this.setState({ diasSelecionados });
                        }}
                      />
                      {dia.charAt(0).toUpperCase()}
                    </label>
                  )
                )}
              </div>
            </div>

            <div className="col-sm-6 mt-auto">
              <div
                className="btn btn-md btn-vk w-100 mt-2"
                type="button"
                onClick={this.handleAdd}
              >
                Adicionar turno
              </div>
            </div>
            <hr />
          </div>
          {disponibilidade.map((turno, index) => (
            <div className="container-fluid" key={index}>
              <div className="row">
                <div className="col-sm-6 fs-4">
                  <b> {turno.turno}° turno </b>
                </div>
                <div className="col-sm-6">
                  <button
                    className="btn btn-outline-vk"
                    type="button"
                    disabled={
                      turno.turno === 1 ||
                      turno !== disponibilidade[disponibilidade.length - 1]
                    }
                    onClick={() => this.handleRemove(index)}
                  >
                    <TrashFill />
                  </button>
                </div>
                <div className="col-sm-6">
                  <label htmlFor="horaInicio" className="form-label">
                    Hora Início:
                  </label>
                  <input
                    type="time"
                    className="form-control input-md"
                    name="horaInicio"
                    defaultValue={turno.horaInicio}
                    required
                  />
                </div>
                <div className="col-sm-6">
                  <label htmlFor="horaFim" style={{ marginTop: "10px" }}>
                    Hora Fim:
                  </label>
                  <input
                    type="time"
                    className="form-control input-md"
                    name="horaFim"
                    required
                    defaultValue={turno.horaFim}
                  />
                </div>
              </div>
              <hr />
            </div>
          ))}
          <div className="col-sm-6 mt-auto">
            <button
              type="submit"
              className="button-form input-sm btn btn-vk mt-2 w-100"
            >
              Salvar
            </button>
          </div>
          <div className="col-sm-6 mt-auto">
            <button
              className="button-form input-sm btn btn-outline-secondary mt-2 w-100"
              onClick={() => this.props.hideModal()}
            >
              Fechar
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default ModalAgenda;

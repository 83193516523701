import React, { useState } from "react";
import FormSlide from "./FormSlide";

export default function PageSlide() {
  return (
    <div className="container mt-4">
      <div className="border-bottom mb-4">
        <h3>Imagens Loja</h3>
        <FormSlide tipoLoja="loja" formId="form-loja" />
      </div>
    </div>
  );
}

import React, { useState } from "react";
import Perfil from "../Perfil/Perfil";
import Empresas from "../Estabelecimento/Estabelecimentos";
import FormCadastro from "../Estabelecimento/FormCadastro";
import Planos from "../Planos/Planos";
import Usuarios from "../Usuarios/Usuarios";
import PageSlide from "../Slide/PageSldie";
import { useAuthContext } from "../../context/AuthContext";
import Desconto from "../Desconto/Desconto";

function Config() {
  const [activeTab, setActiveTab] = useState("perfil");
  const { userData } = useAuthContext();

  const tabs = [
    { key: "perfil", label: "Perfil", component: <Perfil />, roles: [] },
    { key: "imagensSlide", label: "Imagens Slide", component: <PageSlide />, roles: [] },
    { key: "cadastro", label: "Meus dados", component: <FormCadastro />, roles: ["super", "admin"] },
    { key: "desconto", label: "Desconto Geral", component: <Desconto />, roles: ["super", "admin"] },
    { key: "usuarios", label: "Usuarios", component: <Usuarios />, roles: ["super", "admin"] },
    { key: "empresas", label: "Empresas", component: <Empresas />, roles: ["super"] },
    { key: "planos", label: "Planos", component: <Planos />, roles: ["super"] },
    { key: "ajuda", label: "Ajuda", component: <div>Ajuda Content</div>, roles: [] },
  ];

  const renderTabContent = () => {
    const activeTabContent = tabs.find(tab => tab.key === activeTab);
    return activeTabContent ? activeTabContent.component : null;
  };

  const renderTabs = () => {
    return tabs
      .filter(tab => tab.roles.length === 0 || tab.roles.includes(userData.permissionLevel))
      .map(tab => (
        <li className="nav-item" role="presentation" key={tab.key}>
          <button
            className={`nav-link cursor-pointer ${activeTab === tab.key ? "active" : "text-dark"}`}
            onClick={() => setActiveTab(tab.key)}
          >
            {tab.label}
          </button>
        </li>
      ));
  };

  return (
    <div className="container-fluid container">
      <div className="my-4">
        <h2 className="text-center" style={{ color: "#FF5722" }}>
          Configurações
        </h2>
        <ul className="nav nav-tabs">{renderTabs()}</ul>
        <div className="tab-content mt-3">{renderTabContent()}</div>
      </div>
    </div>
  );
}

export default Config;

import { useState } from "react";
import { apiClient } from "../../../service/api/PrestAPI";
import { toast } from "react-hot-toast";
import service from "../service";

export function useCreateEstabelecimento() {
  const [isLoading, setIsLoading] = useState(false);

  const createEstabelecimento = async (estabelecimento) => {
    setIsLoading(true);
    try {
      await service.createEstabelecimento(estabelecimento);
      toast.success("Estabelecimento criado com sucesso!");
    } catch (error) {
      toast.error("Erro ao criar o estabelecimento. Tente novamente.");
      console.error("Erro ao criar estabelecimento:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return { createEstabelecimento, isLoading };
}

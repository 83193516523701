import Select from "react-select";
import React from "react";

export default function EstabelecimentosSelect({
  estabelecimentos,
  filters,
  setFilters,
}) {
  const options =
    estabelecimentos && Array.isArray(estabelecimentos)
      ? estabelecimentos.map((estabelecimento) => ({
          value: estabelecimento.id_estabelecimento,
          label: estabelecimento.razao_social,
        }))
      : [];

  return (
    <div className="col-12">
      <label className="form-label">Estabelecimento:</label>
      <Select
        options={[{ value: "", label: "Todos" }, ...options]}
        value={
          options.find(
            (option) => option.value === filters.estabelecimento
          ) || { value: "", label: "Todos" }
        }
        onChange={(selectedOption) => {
          setFilters({
            ...filters,
            estabelecimento: selectedOption ? selectedOption.value : "",
          });
        }}
        placeholder="Selecione um estabelecimento"
        isClearable
      />
    </div>
  );
}

export const validateImage = (image) => {
  if (!image) {
    return "Nenhuma imagem foi fornecida.";
  }

  const validTypes = ["image/jpeg", "image/png"];
  const validExtensions = ["jpg", "jpeg", "png"];
  const maxSize = 10 * 1024 * 1024; // 10 MB

  if (image.size > maxSize) {
    return "O tamanho da imagem não pode exceder 10MB.";
  }

  const extension = image.name.split(".").pop().toLowerCase();
  if (!validExtensions.includes(extension)) {
    return "A extensão da imagem é inválida. Permitido apenas arquivos JPG, JPEG e PNG.";
  }

  if (!validTypes.includes(image.type)) {
    return "O tipo do arquivo é inválido. Permitido apenas imagens JPEG e PNG.";
  }

  return null;
};

import React from "react";
import { Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Parceiro from "../pages/Parceiro/Parceiro";
import NotFound from "../pages/NotFound/NotFound";

export const PartnerRoute = () => {
  const url = window.location.href;

  const partnerMapping = {
    vempraka: "vempraka",
    anoteja: "anoteja",
    mrstecno: "mrstecno",
  };

  const parceiroName = Object.keys(partnerMapping).find((key) =>
    url.includes(key)
  );

  return (
    <>
      <Toaster />
      <Routes>
        <Route path="/" element={<Parceiro name={parceiroName} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { apiClient, apiClientGatepeeker } from "../service/api/PrestAPI";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import DOMPurify from "dompurify";

const AuthContext = createContext();

const getSanitizedLocalStorageItem = (key, defaultValue = "") =>
  DOMPurify.sanitize(localStorage.getItem(key) || defaultValue);

const setSanitizedLocalStorageItem = (key, value) =>
  localStorage.setItem(key, DOMPurify.sanitize(value || ""));

export const AuthContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const [token, setToken] = useState(() =>
    getSanitizedLocalStorageItem("accessToken")
  );
  const [estabelecimento, setEstabelecimento] = useState(() =>
    JSON.parse(getSanitizedLocalStorageItem("estabelecimento", "null"))
  );
  const [userData, setUserData] = useState(() =>
    JSON.parse(getSanitizedLocalStorageItem("userData", "null"))
  );
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(
    () => !!(token && userData && estabelecimento)
  );
  const [credentials, setCredentials] = useState(null);

  useEffect(() => {
    setSanitizedLocalStorageItem("accessToken", token || "");
    localStorage.setItem(
      "estabelecimento",
      JSON.stringify(estabelecimento) || ""
    );
    localStorage.setItem("userData", JSON.stringify(userData) || "");
  }, [token, estabelecimento, userData]);

  const checkStoragedAuth = () => {
    if (userData && token && estabelecimento) {
      setIsLoggedIn(true);
      return;
    }
  };

  useEffect(() => {
    checkStoragedAuth();
  }, []);

  const login = async (credentials) => {
    setLoading(true);
    try {
      const response = await apiClientGatepeeker.post("panel", credentials);

      if (response.data && response.data.token && response.data.value) {
        const token = DOMPurify.sanitize(response.data.token);
        const { uid, store_uid, name, role, email } = response.data.value;

        const user = {
          name,
          role,
          email,
          permissionLevel: getPermissionLevel(role),
          uid,
          store_uid,
        };

        setToken(token);
        setSanitizedLocalStorageItem("accessToken", token || "");
        setUserData(user);
        setCredentials(credentials);
        setIsLoggedIn(true);

        const encryptedData = CryptoJS.AES.encrypt(
          JSON.stringify({ uid, ticket: credentials.password }),
          process.env.REACT_APP_ENCRYPT_PASS
        ).toString();
        localStorage.setItem("Estab", encryptedData);

        await updateEstabelecimento(store_uid);
        return true;
      }
    } catch (error) {
      console.error("Erro durante o login:", error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const updateEstabelecimento = async (store_uid) => {
    try {
      const estabelecimentoResponse = await apiClient.get(
        `estabelecimento/${store_uid}`
      );

      if (
        estabelecimentoResponse.data &&
        estabelecimentoResponse.data.id_estabelecimento
      ) {
        setEstabelecimento(estabelecimentoResponse.data);
      } else {
        throw new Error("Estabelecimento não encontrado");
      }
    } catch (error) {
      console.error("Erro ao atualizar estabelecimento:", error);
    }
  };

  apiClient.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;

        try {
          if (!credentials) {
            logout();
            return Promise.reject(error);
          }

          await login(credentials);

          const updatedToken = getSanitizedLocalStorageItem("accessToken");

          originalRequest.headers["Authorization"] = `Bearer ${updatedToken}`;

          return apiClient(originalRequest);
        } catch (tokenError) {
          console.error("Erro ao tentar renovar o token:", tokenError);
          logout();
          return Promise.reject(tokenError);
        }
      }

      if (error.response && error.response.status === 401) {
        logout();
      }

      return Promise.reject(error);
    }
  );

  const getPermissionLevel = useMemo(
    () => (role) => {
      switch (role.toLowerCase()) {
        case "str":
          return "admin";
        case "usr":
          return "user";
        case "sup":
          return "super";
        case "ext":
          return "integração";
        default:
          return "Usuário";
      }
    },
    []
  );

  const logout = () => {
    setEstabelecimento(null);
    setToken(null);
    setUserData(null);
    setCredentials(null);
    setIsLoggedIn(false);
    localStorage.clear();
    navigate("/login");
  };

  return (
    <AuthContext.Provider
      value={{
        estabelecimento,
        login,
        logout,
        loading,
        isLoggedIn,
        userData,
        updateEstabelecimento,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);

export default { AuthContextProvider, useAuthContext };

export const geocodeAddress = async (address) => {
  if (!window.google || !window.google.maps || !window.google.maps.Geocoder) {
    throw new Error("API do Google Maps não está carregada corretamente.");
  }

  const geocoder = new window.google.maps.Geocoder();

  return new Promise((resolve, reject) => {
    geocoder.geocode({ address }, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK && results.length > 0) {
        const location = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
          neighborhood: "",
        };

        const addressComponents = results[0].address_components;
        for (let component of addressComponents) {
          if (component.types.includes("neighborhood")) {
            location.neighborhood = component.long_name;
            break;
          }
          if (component.types.includes("sublocality") || component.types.includes("sublocality_level_1")) {
            location.neighborhood = component.long_name;
            break;
          }
        }

        resolve(location);
      } else {
        reject(new Error("Geolocalização não encontrada para o CEP fornecido."));
      }
    });
  });
};

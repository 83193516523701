import { useState } from "react";
import { apiClient } from "../../../service/api/PrestAPI";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
export const useProdutoGrupo = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const addProdutoGrupo = async (grupo) => {
    setLoading(true);
    try {
      const res = await apiClient.post("ProdutoGrupo", grupo);
      if (res.status === 200) {
        toast.success("Categoria adicionada com sucesso.");
        navigate("/produto");
      } else {
        toast.error(res.data.error || "Erro ao adicionar Categoria.");
      }
    } catch (error) {
      console.error("Erro ao adicionar grupo:", error);
      toast.error("Erro ao adicionar grupo. Por favor, tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  const updateProdutoGrupo = async (grupo) => {
    setLoading(true);
    try {
      const res = await apiClient.put(`ProdutoGrupo?id_grupo=${grupo.id_grupo}`, grupo);
      if (res.status === 200 || res.status === 202) {
        toast.success("Categoria atualizada com sucesso.");
        navigate("/produto");
      }
    } catch (error) {
      const errorMessage =
        error.response.data.message || error.message || "Ocorreu um erro desconhecido";
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return { addProdutoGrupo, updateProdutoGrupo, loading };
};

import { useState, useEffect } from "react";
import service from "../service";
import { useAuthContext } from "../../../context/AuthContext";

const useGetEstabelecimentos = () => {
  const { userData } = useAuthContext();
  const [estabelecimentos, setEstabelecimentos] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchEstabelecimentos = async () => {
    setLoading(true);
    try {
      const result = await service.getEstabelecimentos();
      const combinedEstabelecimentos = result.data.estabelecimentos.flat();
      setEstabelecimentos(combinedEstabelecimentos);
    } catch (err) {
      setError("Erro ao obter estabelecimento");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (userData.permissionLevel === "super") {
      fetchEstabelecimentos();
    }
  }, []);

  return { estabelecimentos, loading, error };
};

export default useGetEstabelecimentos;

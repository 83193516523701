import { useState } from "react";
import { apiClient } from "../../../service/api/PrestAPI";
import service from "../service";

export const useRegisterUsuario = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const addUsuario = async (dadosUsuario) => {
    setLoading(true);
    setError(null);
    try {
      const response = await service.createUsuario(dadosUsuario);
      return response.data;
    } catch (error) {
      const errorMessage =
        error.response.data.message ||
        "Ocorreu um erro ao tentar registrar o usuário.";
      setError(errorMessage);
      console.error("Failed to register user:", errorMessage);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { addUsuario, loading, error };
};

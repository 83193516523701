import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Style.css";
import { Accordion, Card, Spinner } from "react-bootstrap";
import * as PrestAPI from "../../service/api/PrestAPI";
import { useAuthContext } from "../../context/AuthContext";
import { PencilFill } from "react-bootstrap-icons";

function Produto() {
  const [categorias, setCategorias] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [statusFiltro, setStatusFiltro] = useState("Ativo");
  const [activeKeys, setActiveKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const { estabelecimento } = useAuthContext();
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();

  const getDados = useCallback(async () => {
    setLoading(true);
    try {
      const [respCategorias, respProdutos] = await Promise.all([
        PrestAPI.get(
          "ProdutoGrupo",
          `estab=${estabelecimento.id_estabelecimento}&modo=retaguarda`
        ),
        PrestAPI.get(
          "Produto",
          `estab=${estabelecimento.id_estabelecimento}&modo=retaguarda`
        ),
      ]);

      setCategorias(Array.isArray(respCategorias.categorias) ? respCategorias.categorias : []);
      setProdutos(Array.isArray(respProdutos.produtos) ? respProdutos.produtos : []);
      
      setActiveKeys(respCategorias.categorias && respCategorias.categorias.map((cat) => cat.id_grupo));
    } catch (error) {
      console.error("Erro ao carregar dados:", error);
    } finally {
      setLoading(false);
    }
  }, [estabelecimento]);

  useEffect(() => {
    getDados();
  }, [getDados]);

  const editProduto = (produto) => {
    navigate("/produto-edit", { state: { produto, isProdutoNew: false } });
  };

  const editGrupo = (grupo) => {
    navigate("/produto_grupo-edit", { state: { grupo } });
  };

  const handleAccordionToggle = (id_grupo) => {
    setActiveKeys((prevKeys) =>
      prevKeys.includes(id_grupo)
        ? prevKeys.filter((key) => key !== id_grupo)
        : [...prevKeys, id_grupo]
    );
  };

  const handleNewProductClick = (categoria) => async (e) => {
    e.stopPropagation();
    setIsProcessing(true);
    try {
      navigate("/produto-add", {
        state: { isProdutoNew: true, selectedCategoria: categoria.descricao },
      });
    } finally {
      setIsProcessing(false);
    }
  };

  const filterProdutos = useCallback((categoria) => {
    return (
      produtos &&
      produtos.filter((produto) => {
        const matchesStatus =
          statusFiltro === "Todos" ||
          (statusFiltro === "Ativo" && produto.status_produto === 1) ||
          (statusFiltro === "Inativo" && produto.status_produto === 0);
        return produto.id_grupo === categoria.id_grupo && matchesStatus;
      })
    );
  }, [produtos, statusFiltro]);
  
  if (loading) {
    return (
      <div className="text-center mt-5">
        <div className="loader" id="loader">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="row mt-4 mb-4 justify-content-between">
        <div className="col-lg-6">
          <Link to="/produto_grupo-add" className="btn btn-vk px-2 py-1">
            Adicionar categoria
          </Link>
        </div>
        <div className="col-lg-4">
          <div className="row align-items-center">
            <label className="col-sm-5 col-form-label text-center">
              Status do Produto:
            </label>
            <div className="col-lg-7">
              <select
                className="form-select"
                id="statusFiltro"
                onChange={(e) => setStatusFiltro(e.target.value)}
              >
                <option>Ativo</option>
                <option>Inativo</option>
                <option>Todos</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <Accordion activeKey={activeKeys}>
      {categorias && categorias.length > 0 && categorias.map((categoria) => (
          <Card key={categoria.id_grupo} className="mb-3">
            <Card.Header
              style={{ cursor: "pointer" }}
              onClick={() => handleAccordionToggle(categoria.id_grupo)}
            >
              <div className="row my-3">
                <div className="col-lg-6 text-center">
                  <h3>{categoria.descricao}</h3>
                </div>
                <div className="col-lg-6 row justify-content-end">
                  <div className="col-lg-4">
                    <button
                      className="btn btn-vk w-100 mt-2"
                      onClick={handleNewProductClick(categoria)}
                    >
                      Novo produto
                    </button>
                  </div>
                  <div className="col-lg-4">
                    <button
                      className="btn btn-vk mt-2 w-100"
                      onClick={() => editGrupo(categoria)}
                    >
                      Editar categoria
                    </button>
                    <strong className="mt-2">
                      Nr-sequência: {categoria.nr_sequencia}
                    </strong>
                  </div>
                </div>
              </div>
            </Card.Header>
            <Accordion.Collapse eventKey={categoria.id_grupo}>
              <Card.Body>
                {loading ? (
                  <div className="text-center">
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  filterProdutos && filterProdutos.length > 0 &&
                  filterProdutos(categoria).map((produto) => (
                    <div key={produto.id_produto} className="p-2">
                      <div className="row tabProduto justify-content-between">
                        <div className="col-sm-2 text-center">
                          <img
                            src={produto.imagem || estabelecimento.imagem}
                            alt="Produto"
                            style={{
                              maxWidth: "50%",
                              height: "50px",
                              borderRadius: "20%",
                            }}
                          />
                        </div>
                        <div className="col-sm-5 fs-5">{produto.nome}</div>
                        <div className="col-sm-3">
                          <h4>
                            {new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            }).format(produto.vr_unitario)}
                          </h4>
                        </div>
                        <div className="col-sm-2 text-center">
                          <button
                            className="btn btn-vk-edit"
                            onClick={() => editProduto(produto)}
                            aria-label={`Editar ${produto.nome}`}
                          >
                            <PencilFill />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    </div>
  );
}

export default Produto;

import { apiClient } from "../../service/api/PrestAPI";

export const createBenefit = async (data) => {
  const response = await apiClient.post("/benefits", data);
  return response.data;
};

export const getBenefitsByStore = async (storeUid) => {
  const response = await apiClient.get(`/benefits/${storeUid}`);
  return response.data;
};

export const getBenefitById = async (storeUid, id) => {
  const response = await apiClient.get(`/benefits/${storeUid}/${id}`);
  return response.data;
};

export const getBenefitByCode = async (storeUid, codeCupom) => {
  const response = await apiClient.get(
    `/benefits/${storeUid}/code/${codeCupom}`
  );
  return response.data;
};

export const updateBenefit = async (storeUid, id, data) => {
  const response = await apiClient.put(`/benefits/${storeUid}/${id}`, data);
  return response.data;
};

export const deleteBenefit = async (storeUid, id) => {
  const response = await apiClient.delete(`/benefits/${storeUid}/${id}`);
  return response.data;
};

export default {
  createBenefit,
  getBenefitsByStore,
  getBenefitById,
  getBenefitByCode,
  updateBenefit,
  deleteBenefit,
};

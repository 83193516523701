import { apiClient } from "../../service/api/PrestAPI";

const createUsuario = async (usuario) => {
  return await apiClient.post("/user", usuario);
};
const updateUsuario = async (usuario) => {
  return await apiClient.put("/user", usuario);
};
const deleteUsuario = async (usuario) => {
  return await apiClient.delete("/user", usuario);
};

const getUsuarios = async (usuario) => {
  return await apiClient.get("/user");
};

export default { createUsuario, updateUsuario, deleteUsuario, getUsuarios };

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import * as PrestAPI from "../../service/api/PrestAPI";
import ReactLoading from "react-loading";
import "../Produto/Components/styles.css";
import { toast } from "react-hot-toast";
import { useGetTaxas } from "../../features/Taxas/hooks/useGetTaxas";
import { useDeleteTaxa } from "../../features/Taxas/hooks/useDeleteTaxa";

const TaxaEntregaList = ({ estabelecimento, editTaxa, setNovaTaxa }) => {
  TaxaEntregaList.propTypes = {
    estabelecimento: PropTypes.object.isRequired,
    editTaxa: PropTypes.func.isRequired,
    setNovaTaxa: PropTypes.func.isRequired,
  };
  const [tipoFiltro, setTipoFiltro] = useState("Todos");
  const { taxas, loading, refreshTaxas } = useGetTaxas();
  const { deleteTaxa, isDeleting } = useDeleteTaxa();

  const handleDeleteTaxa = (taxa) => {
    toast.error(
      <div className="col pe-2 ps-1" style={{ maxWidth: "300px" }}>
        <div>
          <b>{`Id taxa: ${taxa.id_taxa}`}</b>
        </div>
        <div>{taxa.bairro}</div>
        <div>{`Tem certeza que deseja excluir?`}</div>
        <hr />
        <div className="row">
          <div className="col-6">
            <button
              onClick={() => {
                confirmDeleteTaxa(taxa);
                toast.dismiss(taxa.id_taxa);
              }}
              className="btn btn-primary w-100"
            >
              Sim
            </button>
          </div>
          <div className="col-6">
            <button
              className="btn btn-outline-vk w-100"
              onClick={() => toast.dismiss(taxa.id_taxa)}
            >
              Não
            </button>
          </div>
        </div>
      </div>,
      { id: taxa.id_taxa }
    );
  };

  const confirmDeleteTaxa = async (taxa) => {
    await deleteTaxa(taxa, refreshTaxas);
  };

  const filteredTaxas =
    Array.isArray(taxas) &&
    taxas.filter((taxa) => {
      if (tipoFiltro === "Bairro") return taxa.cep_inicio === "";
      if (tipoFiltro === "CEP") return taxa.cep_inicio !== "";
      return true;
    });

  return (
    <div className="container-fluid">
      <div className="container">
        <div className="row justify-content-between align-items-end mb-2">
          <div className="col-sm-6">
            <h3>Taxas</h3>
          </div>
          <div className="row col-sm-3 g-2 align-items-end">
            <label className="col-sm-6 col-form-label">Tipo do Cadastro:</label>
            <div className="col-sm-6">
              <select
                className="form-select"
                id="tipoFiltro"
                value={tipoFiltro}
                onChange={(e) => setTipoFiltro(e.target.value)}
              >
                <option value="Todos">Todos</option>
                <option value="Bairro">Bairro</option>
                <option value="CEP">CEP</option>
              </select>
            </div>
          </div>
          <div className="col-sm-3">
            <Link
              to="/taxa_entrega-add"
              className="btn btn-vk w-100"
              onClick={() => setNovaTaxa()}
            >
              Adicionar Taxa
            </Link>
          </div>
        </div>
        {!loading ? (
          <div className="row table-responsive">
            <div className="col-sm-12">
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th className="col-xs-1">Id.Taxa</th>
                    <th className="col-xs-9">Bairro</th>
                    <th className="col-xs-1">Vr.Taxa</th>
                    <th className="col-xs-1">Editar</th>
                    <th className="col-xs-1">Excluir</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredTaxas &&
                    filteredTaxas.map((taxa) => (
                      <tr key={taxa.id_taxa}>
                        <td>{taxa.id_taxa}</td>
                        <td>{taxa.bairro}</td>
                        <td>
                          {new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(taxa.valor_taxa)}
                        </td>
                        <td>
                          <button
                            className="btn btn-vk w-100"
                            onClick={() => editTaxa(taxa)}
                          >
                            Editar
                          </button>
                        </td>
                        <td>
                          <button
                            className="btn btn-outline-vk w-100"
                            onClick={() => handleDeleteTaxa(taxa)}
                          >
                            Excluir
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="loaderApp">
            <ReactLoading
              type="spinningBubbles"
              color="#ff5722"
              height={100}
              width={100}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TaxaEntregaList;

import { useState, useEffect, useCallback, useRef } from "react";
import { apiClient } from "../../../service/api/PrestAPI";
import toast from "react-hot-toast";
import som from "../../../assets/audios/audio.mp3";

export const useBuscaPedidos = (estabelecimento) => {
  const [pedidosConfirmar, setPedidosConfirmar] = useState(false);
  const audioRef = useRef(new Audio(som));
  const isMounted = useRef(true);
  const isFetching = useRef(false);

  const playAudio = useCallback(() => {
    if (audioRef.current.paused) {
      audioRef.current.play().catch((err) => {
        console.error("Erro ao reproduzir áudio:", err);
      });
    }
  }, []);

  const buscaPedidos = useCallback(async () => {
    if (!estabelecimento || isFetching.current) return;

    isFetching.current = true;
    try {
      const { data } = await apiClient.get(
        `Pedidos?estab=${estabelecimento.id_estabelecimento}`
      );

      if (!isMounted.current) return;

      const hasPendingOrders = data.pedidos.some(
        (pedido) => pedido.status_pedido === "PENDENTE"
      );

      if (hasPendingOrders) {
        toast.dismiss("Pedidos");
        toast(`Você tem pedidos para confirmar!`, {
          icon: "🛎️",
          id: "Pedidos",
        });
        playAudio();
        setPedidosConfirmar(true);
      } else {
        setPedidosConfirmar(false);
      }
    } catch (error) {
      console.error("Erro ao buscar pedidos:", error);
    } finally {
      isFetching.current = false;
    }
  }, [estabelecimento, playAudio]);

  useEffect(() => {
    isMounted.current = true;

    if (estabelecimento) buscaPedidos();

    const interval = setInterval(() => {
      if (isMounted.current) buscaPedidos();
    }, 30000);

    return () => {
      clearInterval(interval); 
      isMounted.current = false;
      audioRef.current.pause();
      audioRef.current.currentTime = 0; 
    };
  }, [estabelecimento, buscaPedidos]);

  return { pedidosConfirmar, buscaPedidos };
};

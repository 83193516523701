import React, { useState } from "react";

const HorariosAtendimento = ({ horarios = {}, onSaveHorarios }) => {
  const inicializarHorarios = () => {
    const defaultHorarios = {
      domingo: { horaInicio: "00:00", horaFim: "00:00" },
      segunda: { horaInicio: "00:00", horaFim: "00:00" },
      terca: { horaInicio: "00:00", horaFim: "00:00" },
      quarta: { horaInicio: "00:00", horaFim: "00:00" },
      quinta: { horaInicio: "00:00", horaFim: "00:00" },
      sexta: { horaInicio: "00:00", horaFim: "00:00" },
      sabado: { horaInicio: "00:00", horaFim: "00:00" },
    };

    const diasSelecionados = {
      domingo: false,
      segunda: false,
      terca: false,
      quarta: false,
      quinta: false,
      sexta: false,
      sabado: false,
    };

    Object.keys(horarios).forEach((horarioKey) => {
      if (horarios[horarioKey]) {
        const [horaInicio, horaFim] = horarios[horarioKey].split("|") || [
          "00:00",
          "00:00",
        ];
        const dia = horarioKey.replace("horario_", "");

        if (defaultHorarios[dia]) {
          defaultHorarios[dia] = { horaInicio, horaFim };
          diasSelecionados[dia] = true;
        }
      }
    });

    return { defaultHorarios, diasSelecionados };
  };

  const { defaultHorarios, diasSelecionados: inicialDiasSelecionados } =
    inicializarHorarios();

  const [disponibilidadePorDia, setDisponibilidadePorDia] =
    useState(defaultHorarios);
  const [diasSelecionados, setDiasSelecionados] = useState(
    inicialDiasSelecionados
  );

  const diaMappingDisplay = {
    domingo: "domingo",
    segunda: "segunda",
    terca: "terça",
    quarta: "quarta",
    quinta: "quinta",
    sexta: "sexta",
    sabado: "sábado",
  };

  const handleChangeDias = (dia) => {
    const updatedDias = {
      ...diasSelecionados,
      [dia]: !diasSelecionados[dia],
    };
    setDiasSelecionados(updatedDias);

    atualizarHorarios(updatedDias, disponibilidadePorDia);
  };

  const handleTimeChange = (dia, type, value) => {
    const newDisponibilidade = {
      ...disponibilidadePorDia,
      [dia]: {
        ...disponibilidadePorDia[dia],
        [type]: value,
      },
    };
    setDisponibilidadePorDia(newDisponibilidade);

    atualizarHorarios(diasSelecionados, newDisponibilidade);
  };

  const atualizarHorarios = (
    diasSelecionadosAtualizados,
    disponibilidadeAtualizada
  ) => {
    const selectedDays = Object.keys(diasSelecionadosAtualizados).filter(
      (dia) => diasSelecionadosAtualizados[dia]
    );

    const novosHorarios = selectedDays.reduce((acc, dia) => {
      const { horaInicio, horaFim } = disponibilidadeAtualizada[dia];
      acc[`horario_${dia}`] = `${horaInicio}|${horaFim}`;
      return acc;
    }, {});

    onSaveHorarios(novosHorarios);
  };

  return (
    <div className="container-fluid py-3 rounded overflow-x-hidden overflow-y-auto z-2 m-0">
      <div className="row g-2 mb-5">
        <div className="row g-2">
          <div className="row col mb-2">
            <label className="form-label w-100 fs-5">Dias da semana:</label>
            <div className="btn-group row w-100" role="group">
              {Object.keys(diasSelecionados).map((dia, index) => (
                <label
                  key={index}
                  className={`btn btn-outline-vk fs-5 btn-md col-lg-1 ${
                    diasSelecionados[dia] ? "active" : ""
                  }`}
                >
                  <input
                    type="checkbox"
                    className="btn-check"
                    checked={diasSelecionados[dia]}
                    onChange={() => handleChangeDias(dia)}
                  />
                  {diaMappingDisplay[dia].charAt(0).toUpperCase() +
                    diaMappingDisplay[dia].slice(1)}
                </label>
              ))}
            </div>
          </div>
        </div>
        <div className="row">
          {Object.keys(diasSelecionados).map(
            (dia) =>
              diasSelecionados[dia] && (
                <div key={dia} className="col-lg-4 border p-1">
                  <h5 className="text-capitalize">{diaMappingDisplay[dia]}</h5>
                  <div className="row">
                    <div className="col-sm-6">
                      <label htmlFor="horaInicio" className="form-label">
                        Hora Início:
                      </label>
                      <input
                        type="time"
                        className="form-control input-md"
                        name="horaInicio"
                        value={disponibilidadePorDia[dia].horaInicio}
                        onChange={(e) =>
                          handleTimeChange(dia, "horaInicio", e.target.value)
                        }
                        required
                      />
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="horaFim" className="form-label">
                        Hora Fim:
                      </label>
                      <input
                        type="time"
                        className="form-control input-md"
                        name="horaFim"
                        value={disponibilidadePorDia[dia].horaFim}
                        onChange={(e) =>
                          handleTimeChange(dia, "horaFim", e.target.value)
                        }
                        required
                      />
                    </div>
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default HorariosAtendimento;

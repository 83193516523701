import React, { useState, useEffect } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import PropTypes from "prop-types";
import serializeForm from "form-serialize";
import TaxaEntregaGeo from "./TaxaEntregaGeo";
import { useGetTaxas } from "../../features/Taxas/hooks/useGetTaxas";

const TaxaEntregaForm = ({ taxa, saveTaxa, isNewTaxa }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [usaGeo, setUsaGeo] = useState(false);
  const [usaRange, setUsaRange] = useState(false);
  const [usaBairro, setUsaBairro] = useState(false);
  const [location, setLocation] = useState({ lat: 0, lng: 0 });
  const { taxas } = useGetTaxas();

  useEffect(() => {
    if (taxa) {
      const usaGeo =
        !isNewTaxa &&
        taxa.latitude !== 0 &&
        taxa.longitude !== 0 &&
        taxa.cep_inicio !== "" &&
        taxa.cep_fim === "";
      const usaRange = taxa.cep_fim !== "";
      const usaBairro = !usaGeo && !usaRange;

      setUsaGeo(usaGeo);
      setUsaRange(usaRange);
      setUsaBairro(usaBairro);
    }
  }, [taxa, isNewTaxa]);

  const isDuplicateBairro = (bairro) => {
    return taxas.some(
      (tax) =>
        tax.bairro.toLowerCase() === bairro.toLowerCase() &&
        tax.id_taxa !== taxa.id_taxa
    );
  };
  const isDuplicateCep = (cep) => {
    return taxas.some(
      (tax) => tax.cep_inicio === cep && tax.id_taxa !== taxa.id_taxa
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;

    try {
      const data = serializeForm(form, { hash: true });

      if (isDuplicateBairro(data.bairro)) {
        toast.error("Este bairro já possui uma taxa cadastrada.");
        return;
      }

      if (isDuplicateCep(data.cep_inicio)) {
        toast.error("Este CEP já possui uma taxa cadastrada.");
        return;
      }
      data.id_estabelecimento = Number.parseInt(taxa.id_estabelecimento, 10);
      data.valor_taxa = parseFloat(data.valor_taxa);
      data.distancia = parseFloat(data.distancia);
      data.latitude = location.lat ? location.lat : taxa.latitude;
      data.longitude = location.lng ? location.lng : taxa.longitude;

      if (saveTaxa) {
        await saveTaxa(taxa.id_taxa, data);
      }
    } catch (error) {
      console.error("Erro ao salvar a taxa:", error);
    }
  };

  return (
    <div className="container-fluid">
      <Tabs
        selectedIndex={tabIndex}
        onSelect={(tabIndex) => setTabIndex(tabIndex)}
      >
        <TabList className="container">
          {isNewTaxa ? (
            <>
              <Tab>Adicionar por bairro</Tab>
              {/* <Tab>Adicionar por limite de CEP</Tab> */}
              <Tab>Adicionar por localização</Tab>
            </>
          ) : (
            <>
              {/* {usaRange && <Tab>Editar limite de CEP</Tab>} */}
              {usaGeo && <Tab>Editar localização</Tab>}
              {usaBairro && <Tab>Editar Bairro</Tab>}
            </>
          )}
        </TabList>

        {isNewTaxa || usaBairro ? (
          <TabPanel className="container">
            <div className="col-xl-8 row mx-auto justify-content-center">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="fs-4">Taxa de Entrega (Bairro) - Cadastro</h3>
                </div>
                <div className="col-md-6">
                  <Link to="/taxa_entrega-add" className="btn btn-vk w-100">
                    Adicionar Taxa
                  </Link>
                </div>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="row mt-2 align-items-end">
                  <div className="col-sm-12" hidden>
                    <label className="form-label">Id.Estabelecimento:</label>
                    <input
                      type="text"
                      name="id_estabelecimento"
                      defaultValue={taxa.id_estabelecimento || 0}
                      className="form-control input-sm"
                    />
                  </div>
                  <div className="col-sm-4 mb-2">
                    <label className="form-label">Id.Taxa:</label>
                    <input
                      type="text"
                      name="id_taxa"
                      defaultValue={taxa.id_taxa}
                      className="form-control input-sm"
                      disabled
                    />
                  </div>
                  <div className="col-sm-4 mb-2">
                    <label className="form-label">Vr.Taxa:</label>
                    <input
                      type="number"
                      name="valor_taxa"
                      defaultValue={taxa.valor_taxa}
                      className="form-control input-sm"
                      required
                    />
                  </div>
                  <div className="col-sm-4 mb-2">
                    <label className="form-label">
                      Tempo de entrega (em minutos):
                    </label>
                    <input
                      type="number"
                      name="tempo_entrega"
                      defaultValue={taxa.tempo_entrega}
                      className="form-control input-sm"
                    />
                  </div>
                  <div className="col-sm-6 mb-2">
                    <label className="form-label">Bairro:</label>
                    <input
                      type="text"
                      name="bairro"
                      defaultValue={taxa.bairro}
                      className="form-control input-sm"
                      required
                    />
                  </div>
                  <div className="col-sm-6 mb-2">
                    <label className="form-label">Cidade:</label>
                    <input
                      type="text"
                      name="cidade"
                      defaultValue={taxa.cidade}
                      className="form-control input-sm"
                      required
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-sm-3">
                    <Link
                      to="/taxa_entrega-list"
                      className="btn btn-outline-secondary btn-lg w-100"
                    >
                      Voltar
                    </Link>
                  </div>
                  <div className="col-sm-9">
                    <button type="submit" className="btn btn-vk btn-lg w-100">
                      Salvar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </TabPanel>
        ) : null}

        {isNewTaxa || usaGeo ? (
          <TabPanel className="container">
            <div className="col-xl-8 mx-auto justify-content-center">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="fs-4">Taxa de Entrega (Por Km)</h3>
                </div>
                <div className="col-md-6">
                  <Link to="/taxa_entrega-add" className="btn btn-vk w-100">
                    Adicionar Taxa
                  </Link>
                </div>
              </div>

              <form onSubmit={handleSubmit}>
                <TaxaEntregaGeo
                  taxa={taxa}
                  saveTaxa={() => {}}
                  isNewTaxa={false}
                  onChangeLocation={(params) => setLocation(params)}
                />

                <div className="row">
                  <div className="col-sm-3">
                    <Link
                      to="/taxa_entrega-list"
                      className="btn btn-outline-secondary btn-lg w-100"
                      onClick={() => setUsaRange(false)}
                    >
                      Voltar
                    </Link>
                  </div>
                  <div className="col-sm-9">
                    <button type="submit" className="btn btn-vk btn-lg w-100">
                      Salvar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </TabPanel>
        ) : null}
      </Tabs>
    </div>
  );
};

TaxaEntregaForm.propTypes = {
  taxa: PropTypes.object,
  saveTaxa: PropTypes.func.isRequired,
  isNewTaxa: PropTypes.bool.isRequired,
};

export default TaxaEntregaForm;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import ImageInput from "../../components/Imagens/ImageInput";
import * as PrestAPI from "../../service/api/PrestAPI";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "./Style.css";
import CatComponent from "./Components/CatComponente";
import AgendamentoProduto from "./AgendamentoProduto";
import useUploadImage from "../../features/imagens/useUploadImage";
import {
  useAddProduto,
  useUpdateProduto,
} from "../../features/Produtos/hooks/useProduto";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import toast from "react-hot-toast";

const initialState = {
  complementos: [],
  modo: "",
  inicio_oferta: "",
  fim_oferta: "",
  valor_oferta: 0,
  imagem: null,
  status_produto: 1,
  id_produto: null,
  codigo_controle: "",
  nome: "",
  vr_unitario: "",
  tag: "",
  descricao: "",
  id_categoria: 0,
};

function ProdutoForm() {
  ProdutoForm.propTypes = {
    selectedCategoria: PropTypes.string,
  };
  const location = useLocation();
  const navigate = useNavigate();
  const { produto = initialState, isProdutoNew = false, loadingData = false } = location.state || {};

  const [produtoForm, setProdutoForm] = useState(produto);
  const [errors, setErrors] = useState({});
  const [grupos, setGrupos] = useState([]);
  const [modo, setModo] = useState("");
  const [isCompSelected, setIsCompSelected] = useState(false);
  const [selectedComp, setSelectedComp] = useState({});
  const [tabIndex, setTabIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { uploadImage, isLoadingImage } = useUploadImage();
  const { addProduto, loading: adding } = useAddProduto();
  const { updateProduto, loading: updating } = useUpdateProduto();
  const { estabelecimento } = useAuthContext();

  const getDados = async () => {
    setIsLoading(true);

    try {
      const gruposData = await PrestAPI.get(
        "ProdutoGrupo",
        `estab=${estabelecimento.id_estabelecimento}&modo=retaguarda`
      );

      if (gruposData && gruposData.categorias) {
        console.log("gruposData", gruposData);
        setGrupos(gruposData.categorias);
      }

      if (produto && produto.complementos) {
        setProdutoForm((prevForm) => ({
          ...prevForm,
          complementos: produto.complementos,
        }));
      }
      if (produto.id_produto && produto.id_estabelecimento) {
        getCategoriaComplemento(produto.id_produto, produto.id_estabelecimento);
      }

      if (
        produto.inicio_oferta &&
        !isNaN(new Date(produto.inicio_oferta).getTime()) &&
        produto.fim_oferta &&
        !isNaN(new Date(produto.fim_oferta).getTime())
      ) {
        setProdutoForm((prevForm) => ({
          ...prevForm,
          inicio_oferta: new Date(produto.inicio_oferta)
            .toISOString()
            .substring(0, 10),
          fim_oferta: new Date(produto.fim_oferta)
            .toISOString()
            .substring(0, 10),
          valor_oferta: produto.valor_oferta,
        }));
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Erro ao obter dados:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (!isProdutoNew && produto.id_produto === 0) {
      navigate("/produto");
    }
    getDados();
    return () => {
      setIsLoading(false);
    };
  }, []);

  const getCategoriaComplemento = (idProduto, idEstabelecimento) => {
    PrestAPI.get(
      "ProdutoComplemento",
      `estab=${idEstabelecimento}&idProduto=${idProduto}&modo=retaguarda`
    ).then((value) => {
      setProdutoForm((prevForm) => ({
        ...prevForm,
        complementos: value.complementos,
      }));
    });
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setProdutoForm((prevForm) => ({
      ...prevForm,
      [name]: type === "checkbox" ? (checked ? 1 : 0) : value,
    }));
  };

  const handleImageChange = (image) => {
    setProdutoForm((prevForm) => ({
      ...prevForm,
      imagem: image,
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    const { id_produto } = produto;

    const imageUrlUpload =
      produtoForm.imagem !== produto.imagem
        ? await uploadImage(produtoForm.imagem)
        : produto.imagem;

    const data = {
      id_produto: produtoForm.id_produto,
      imagem: imageUrlUpload || "",
      id_estabelecimento: estabelecimento.id_estabelecimento,
      tag: produtoForm.tag,
      id_grupo: parseInt(produtoForm.id_grupo),
      nome: produtoForm.nome,
      vr_unitario: parseFloat(produtoForm.vr_unitario),
      descricao: produtoForm.descricao,
      status_produto: parseInt(produtoForm.status_produto),
      codigo_controle: produtoForm.codigo_controle,
    };
    try {
      let responseData;

      if (isProdutoNew || produtoForm.id_produto === 0) {
        responseData = await addProduto(data);
        if (responseData.success) {
          toast.success("Produto cadastrado com sucesso!");
          setProdutoForm(responseData.data);
          navigate("/produto-edit", {
            state: { produto: responseData.data, isProdutoNew: false, loadingData: adding },
          });
        } else {
          throw new Error(responseData.error);
        }
        setTabIndex(1);
      } else {
        if (modo !== "oferta") {
          responseData = await updateProduto(id_produto, data, modo);
          if (responseData.success) {
            toast.success("Produto atualizado com sucesso!");
            setProdutoForm(responseData.data);
            navigate("/produto-edit", {
              state: { produto: responseData.data, isProdutoNew: false, loadingData: updating },
            });
          } else {
            throw new Error(responseData.error);
          }
        } else if (modo === "oferta") {
          const oferta = {
            inicio_oferta: data.inicio_oferta,
            fim_oferta: data.fim_oferta,
            valor_oferta: parseFloat(data.valor_oferta),
          };
          responseData = await updateProduto(id_produto, oferta, modo);
          if (responseData.success) {
            toast.success("Oferta atualizada com sucesso!");
            setProdutoForm(responseData.data);
            navigate("/produto-edit", {
              state: { produto: responseData.data, isProdutoNew: false },
            });
          } else {
            throw new Error(responseData.error);
          }
        }
      }
    } catch (error) {
      toast.error("Erro ao salvar produto. Tente novamente.");
    }
  };
  const validateForm = () => {
    const {
      nome,
      vr_unitario,
      id_grupo,
      inicio_oferta,
      fim_oferta,
      valor_oferta,
    } = produtoForm;
    const errors = {};
    if (!nome) errors.nome = "O nome do produto é obrigatório.";
    if (!vr_unitario || isNaN(vr_unitario))
      errors.vr_unitario = "Valor unitário inválido.";
    if (!id_grupo) errors.id_grupo = "Grupo é obrigatório.";

    if (modo === "oferta") {
      if (!inicio_oferta || !fim_oferta) {
        errors.oferta_data = "Datas de início e fim são obrigatórias.";
      } else if (new Date(inicio_oferta) > new Date(fim_oferta)) {
        errors.oferta_data = "A data de início deve ser anterior à de fim.";
      }
      if (valor_oferta >= vr_unitario) {
        errors.valor_oferta =
          "O valor da oferta deve ser menor que o valor unitário.";
      }
    }

    return errors;
  };

  const selectComp = (complemento) => {
    setSelectedComp(complemento);
    setIsCompSelected(true);
  };

  const componentBack = () => {
    setSelectedComp({});
    setIsCompSelected(false);
  };

  if (isLoading || loadingData) {
    return (
      <div className="text-center mt-5">
        <div className="loader" id="loader">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }

  return (
    !isProdutoNew && !produtoForm.id_produto ? (
      <div className="row col-md-9  mx-auto">
        <span className="text-danger fs-3">Produto não encontado</span>
      </div>
    ) : (
    <Tabs
      className="container"
      selectedIndex={tabIndex}
      onSelect={(index) => setTabIndex(index)}
    >
      {!isCompSelected ? (
        <>
          <TabList>
            <Tab>{isProdutoNew ? "Cadastro" : "Editar"}</Tab>
            <Tab disabled={isProdutoNew && true}>Complemento</Tab>
            <Tab disabled={isProdutoNew && true}>Oferta</Tab>
            <Tab disabled={isProdutoNew && true}>
              Disponibilidade (Dias da semana)
            </Tab>
          </TabList>
          <TabPanel>
            <div className="row col-md-9  mx-auto">
              <div className="row mt-4">
                <div className="col-lg-6">
                  <h3>Produto - {isProdutoNew ? "Cadastro" : "Editar"}</h3>
                </div>
                {isProdutoNew && (
                  <div className="col-lg-6 d-grid">
                    <button
                      className="btn btn-vk"
                      onClick={() =>
                        navigate("/produto-add", { isProdutoNew: true })
                      }
                    >
                      Novo Produto
                    </button>
                  </div>
                )}
                <div className="col-md-12">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-sm-9">
                        {/* Campos do formulário */}
                        <div className="row">
                          <div className="col-sm-3">
                            <label
                              htmlFor="status"
                              className="form-check-label"
                            >
                              Status:
                            </label>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                name="status_produto"
                                checked={produtoForm.status_produto === 1}
                                id="status"
                                onChange={handleChange}
                                className="form-check-input mt-0"
                                style={{ width: "30px", height: "30px" }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <label className="form-label">Id.Produto:</label>
                            <input
                              type="text"
                              name="id_produto"
                              value={produtoForm.id_produto}
                              className="form-control input-sm"
                              placeholder=""
                              disabled
                            />
                          </div>
                          <div className="col-sm-5">
                            <label className="form-label">
                              Código Controle:
                            </label>
                            <input
                              type="text"
                              name="codigo_controle"
                              value={produtoForm.codigo_controle}
                              className="form-control input-sm"
                              placeholder=""
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-8">
                            <label className="form-label">Nome:</label>
                            <input
                              type="text"
                              name="nome"
                              value={produtoForm.nome}
                              className="form-control input-sm"
                              placeholder=""
                              onChange={handleChange}
                              required
                            />
                            {errors.nome && (
                              <div className="text-danger">{errors.nome}</div>
                            )}
                          </div>
                          <div className="col-sm-3">
                            <label className="form-label">Vr.Unitário:</label>
                            <input
                              type="number"
                              step="0.01"
                              name="vr_unitario"
                              value={produtoForm.vr_unitario}
                              className="form-control input-sm"
                              placeholder=""
                              onChange={handleChange}
                              required
                            />
                            {errors.vr_unitario && (
                              <div className="text-danger">
                                {errors.vr_unitario}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <label className="form-label">Grupo:</label>
                            <select
                              name="id_grupo"
                              className="form-select"
                              id="select_grupo"
                              value={produtoForm.id_grupo}
                              onChange={handleChange}
                            >
                              <option value="">Selecione...</option>
                              {grupos.map((grupo) => (
                                <option
                                  key={grupo.id_grupo}
                                  value={grupo.id_grupo}
                                >
                                  {grupo.descricao
                                    ? grupo.descricao
                                    : "Grupo não definido"}
                                </option>
                              ))}
                            </select>
                            {errors.id_grupo && (
                              <div className="text-danger">
                                {errors.id_grupo}
                              </div>
                            )}
                          </div>
                          <div className="col-sm-5">
                            <label className="form-label">Tag:</label>
                            <input
                              type="text"
                              name="tag"
                              value={produtoForm.tag}
                              className="form-control input-sm"
                              placeholder=""
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <label className="form-label">Imagem:</label>
                        <ImageInput
                          name="imagem"
                          defaultValue={produtoForm.imagem}
                          onImageChange={handleImageChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <label className="form-label">Descrição:</label>
                        <textarea
                          name="descricao"
                          value={produtoForm.descricao}
                          className="form-control input-sm w-100"
                          placeholder=""
                          onChange={handleChange}
                          style={{
                            maxHeight: "150px",
                            minHeight: "40px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-sm-3">
                        <Link
                          to="/produto"
                          className="btn btn-outline-secondary btn-lg w-100"
                        >
                          Voltar
                        </Link>
                      </div>
                      <div className="col-sm-9">
                        <button
                          type="submit"
                          className="btn btn-vk btn-lg w-100"
                          onClick={() => setModo("")}
                          disabled={adding || updating || isLoadingImage}
                        >
                          {adding || updating || isLoadingImage
                            ? "Salvando..."
                            : "Salvar"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </TabPanel>
          {/* Abas Adicionais */}
          {!isProdutoNew && produtoForm.complementos && (
            <>
              <TabPanel>
                <div className="container-fluid">
                  <CatComponent
                    idProduto={produto.id_produto}
                    idEstabelecimento={produto.id_estabelecimento}
                    complementos={produtoForm.complementos}
                    getProdutoComplemento={() => {
                      getCategoriaComplemento(
                        produto.id_produto,
                        produto.id_estabelecimento
                      );
                    }}
                    selectComp={(complemento) => selectComp(complemento)}
                  />
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row justify-item-center">
                  <div className="col-md-6 mx-auto">
                    <h3>Produto - Oferta</h3>
                    <p />
                    <h3>Cadastrar nova oferta:</h3>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-lg-6">
                          <label className="form-label">Data Início:</label>
                          <input
                            type="date"
                            name="inicio_oferta"
                            value={produtoForm.inicio_oferta}
                            className="form-control input-lg"
                            onChange={handleChange}
                          />
                          {errors.oferta_data && (
                            <div className="text-danger">
                              {errors.oferta_data}
                            </div>
                          )}
                        </div>
                        <div className="col-lg-6">
                          <label className="form-label">Data Fim:</label>
                          <input
                            type="date"
                            name="fim_oferta"
                            value={produtoForm.fim_oferta}
                            className="form-control input-lg"
                            onChange={handleChange}
                          />
                          {errors.oferta_data && (
                            <div className="text-danger">
                              {errors.oferta_data}
                            </div>
                          )}
                        </div>
                        <div className="col-lg-12">
                          <label className="form-label">
                            Valor da Oferta R$:
                          </label>
                          <input
                            type="number"
                            step="0.01"
                            name="valor_oferta"
                            value={produtoForm.valor_oferta}
                            className="form-control input-lg"
                            onChange={handleChange}
                          />
                          {errors.valor_oferta && (
                            <div className="text-danger">
                              {errors.valor_oferta}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-sm-3">
                          <Link
                            to="/produto"
                            className="btn btn-outline-secondary btn-lg w-100"
                          >
                            Voltar
                          </Link>
                        </div>
                        <div className="col-sm-9 d-grid">
                          <button
                            type="submit"
                            className="btn btn-vk btn-lg"
                            onClick={() => {
                              setModo("oferta");
                            }}
                            disabled={adding || updating || isLoadingImage}
                          >
                            {adding || updating || isLoadingImage
                              ? "Salvando..."
                              : "Salvar"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <AgendamentoProduto produto={produto} tipo={"produto"} />
              </TabPanel>
            </>
          )}
        </>
      ) : (
        <div className="container-fluid">
          <AgendamentoProduto
            produto={produto}
            tipo={"complemento"}
            complemento={selectedComp}
            voltar={componentBack}
          />
        </div>
      )}
    </Tabs>)
  );
}

export default ProdutoForm;

import { useState, useCallback, useEffect } from "react";
import { apiClient } from "../../../service/api/PrestAPI";

export const useAddProduto = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const addProduto = useCallback(async (produto) => {
    setLoading(true);
    setError(null);

    try {
      const { data } = await apiClient.post("/Produto", produto);
      return { success: true, data };
    } catch (error) {
      setError(error);
      console.error("Erro ao cadastrar produto:", error);
      return { success: false, error };
    } finally {
      setLoading(false);
    }
  }, []);

  return { addProduto, loading, error };
};

export const useUpdateProduto = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateProduto = useCallback(
    async (idProduto, produto, modo = "default") => {
      setLoading(true);
      setError(null);

      try {
        const { data } = await apiClient.put(
          `/Produto?id_produto=${idProduto}&modo=${modo}`,
          produto
        );
        return { success: true, data };
      } catch (error) {
        setError(error);
        console.error("Erro ao atualizar produto:", error);
        return { success: false, error };
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return { updateProduto, loading, error };
};

export const useGetProdutos = (estabelecimentoId) => {
  const [produtos, setProdutos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchProdutos = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const { data } = await apiClient.get(
        `/Produto?estab=${estabelecimentoId}`
      );
      setProdutos(data.produtos);
      return { success: true, data: data.produtos };
    } catch (error) {
      setError(error);
      console.error("Erro ao obter produtos:", error);
      return { success: false, error };
    } finally {
      setLoading(false);
    }
  }, [estabelecimentoId]);

  useEffect(() => {
    if (estabelecimentoId) {
      fetchProdutos();
    }
  }, [estabelecimentoId, fetchProdutos]);

  return { produtos, fetchProdutos, loading, error };
};

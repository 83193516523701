import React, { useState, useEffect } from "react";
import ModalAgendamentoProduto from "react-modal";
import PropTypes from "prop-types";
import AgendaComponent from "./Components/AgendaComponent";
import ModalAgenda from "./Components/ModalAgenda";
import * as PrestAPI from "../../service/api/PrestAPI";
import "./Style.css";
import { toast } from "react-hot-toast";

function AgendamentoProduto({ produto, tipo, complemento, voltar }) {
  const [loading, setLoading] = useState(true);
  const [isNovaAgenda, setIsNovaAgenda] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [agenda, setAgendaState] = useState([]);

  const openModal = () => {
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
  };

  const editNovaAgenda = () => {
    setIsNovaAgenda(false);
  };

  const getDados = async () => {
    setIsNovaAgenda(false);

    try {
      let agenda = [];
      if (tipo === "produto") {
        const result = await PrestAPI.get(
          `Produto?id_produto=${produto.id_produto}&modo=retaguarda`
        );

        if (result.produtos[0].agenda) {
          agenda = result.produtos[0].agenda;
        }
      } else if (this.props.tipo === "complemento") {
        const result = await PrestAPI.get(
          "ProdutoComplemento",
          `estab=${produto.id_estabelecimento}&idProduto=${produto.id_produto}&modo=retaguarda`
        );

        let arrComp = [];

        result.complementos.forEach((cat) => {
          if (
            cat.id_categoria_complemento ===
            complemento.id_categoria_complemento
          ) {
            arrComp = cat.itens.filter(
              (item) =>
                item.id_complemento === this.props.complemento.id_complemento
            );
          }
        });

        if (arrComp.length > 0 && arrComp[0].agenda) {
          agenda = arrComp[0].agenda;
        }
      }

      setAgendaState(agenda);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getDados();
  }, []);

  const setAgenda = (agenda) => {
    setAgendaState(agenda);
  };

  return (
    <div className="container-fluid">
      {!loading ? (
        <div className="row">
          <div className="col-sm-2">
            {tipo === "complemento" && (
              <button
                className="btn btn-sm btn-outline-secondary"
                onClick={() => voltar()}
              >
                Voltar
              </button>
            )}
          </div>
          <div className="row my-2">
            <div className="col-sm-8">
              <h3>
                {tipo === "produto" && "Disponibilidade (Dias da semana)"}
                {tipo === "complemento" &&
                  `Disponibilidade complemento - ${complemento.nome_complemento}`}
              </h3>
            </div>
            <div className="col-sm-4 d-flex justify-content-end align-items-center">
              <button
                className="btn btn-vk btn-md w-100"
                style={{ outline: "none" }}
                onClick={() => {
                  openModal();
                  setIsNovaAgenda(true);
                }}
              >
                Nova Agenda
              </button>
            </div>
          </div>

          <div className="col-sm-12">
            {agenda.length > 0 ? (
              agenda.map((el, index) => (
                <AgendaComponent
                  key={index}
                  produto={produto}
                  agenda={el}
                  numero={index}
                  editNovaAgenda={editNovaAgenda}
                  disponibilidade={el.disponibilidade}
                  setAgenda={setAgenda}
                  tipo={tipo}
                  complemento={complemento}
                />
              ))
            ) : (
              <p>Nenhuma agenda disponível</p>
            )}
          </div>
        </div>
      ) : (
        <div className="loader" id="loader" style={{ marginTop: "50px" }}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      )}

      {/* -------------------MODAL ---------------------------- */}
      <ModalAgendamentoProduto
        ariaHideApp={false}
        isOpen={isModalVisible}
        style={customStyles}
        contentLabel="Modal Agendamento"
        onRequestClose={hideModal}
      >
        <ModalAgenda
          hideModal={hideModal}
          modo={tipo}
          produto={produto}
          setAgenda={setAgenda}
          isNovaAgenda={isNovaAgenda}
          complemento={complemento}
        />
      </ModalAgendamentoProduto>
    </div>
  );
}

export default AgendamentoProduto;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "5px",
    height: "fit-content",
    maxHeight: "100%",
    overflowY: "auto",
  },
  overlay: {
    position: "fixed",
    backgroundColor: "rgba(0.30, 0.20, 0, 0.20)",
  },
};

import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useProdutoGrupo } from "../../features/ProdutoGrupo/hooks/useProdutoGrupo";
import { useAuthContext } from "../../context/AuthContext";

const initialState = {
  id_grupo: 0,
  descricao: "",
  nr_sequencia: 0,
  status: true,
};

const ProdutoGrupoForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { grupo } = location.state || {};
  const [formData, setFormData] = useState(grupo || initialState);

  const { addProdutoGrupo, updateProdutoGrupo, isLoading } = useProdutoGrupo();
  const { estabelecimento } = useAuthContext();

  useEffect(() => {
    if (grupo) {
      setFormData(grupo);
    }
  }, [grupo]);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = {
      descricao: formData.descricao,
      id_grupo: Number.parseInt(formData.id_grupo, 10),
      nr_sequencia: Number(formData.nr_sequencia),
      status: formData.status ? 1 : 0,
      id_estabelecimento: String(estabelecimento.id_estabelecimento),
    };

    if (data.id_grupo) {
      updateProdutoGrupo(data);
    } else {
      addProdutoGrupo(data, data.id_estabelecimento);
    }

    navigate("/produto");
  };

  return (
    <div className="container-fluid container">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <div className="row">
            <div className="col-md-8">
              <h3>Produto Grupo - Cadastro</h3>
            </div>
            <div className="col-md-4">
              <Link to="/produto_grupo-add" className="btn w-100 btn-vk">
                Novo Grupo
              </Link>
            </div>
          </div>

          <div className="col-12">
            <form onSubmit={handleSubmit}>
              <div className="col-sm-9">
                <div className="row">
                  <div className="row">
                    <div className="col-sm-2">
                      <label className="form-check-label">Status:</label>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="status"
                          checked={formData.status}
                          onChange={handleChange}
                          className="form-check-input"
                          style={{ width: "30px", height: "30px" }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <label className="form-label">Id.Grupo:</label>
                      <input
                        type="text"
                        name="id_grupo"
                        value={formData.id_grupo}
                        className="form-control input-sm"
                        placeholder=""
                        readOnly
                      />
                    </div>
                    <div className="col-sm-3">
                      <label className="form-label">Sequência:</label>
                      <input
                        type="number"
                        name="nr_sequencia"
                        value={formData.nr_sequencia || 999}
                        onChange={handleChange}
                        className="form-control input-sm"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-9">
                      <label className="form-label">Descrição:</label>
                      <input
                        type="text"
                        name="descricao"
                        value={formData.descricao}
                        onChange={handleChange}
                        className="form-control input-sm"
                        placeholder=""
                        required
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-sm-3">
                      <Link
                        to="/produto"
                        className="btn btn-outline-secondary btn-lg w-100"
                      >
                        Voltar
                      </Link>
                    </div>
                    <div className="col-sm-9">
                      <button
                        type="submit"
                        className="btn btn-vk btn-lg w-100"
                        disabled={isLoading}
                      >
                        {isLoading ? "Salvando..." : "Salvar"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

ProdutoGrupoForm.propTypes = {
  grupo: PropTypes.object,
};

export default ProdutoGrupoForm;

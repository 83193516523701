import React, { useState, useEffect } from "react";

function Turno({ turno, index }) {
  const [horario, setHorario] = useState([]);

  useEffect(() => {
    if (turno.horario) {
      let arr = turno.horario.split("|");
      setHorario(arr);
    }
  }, [turno]);

  return (
    <div>
      <div className="row">
        <div className="col-sm-6">
          <label>
            <b> {turno.turno}° turno </b>
          </label>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <label htmlFor={`horaInicio-${index}`} className="form-label">
            Hora Início:
          </label>
          <input
            type="time"
            className="form-control input-md"
            id={`horaInicio-${index}`}
            name="horaInicio"
            readOnly
            value={horario[0] || ""}
          />
        </div>
        <div className="col-sm-6">
          <label htmlFor={`horaFim-${index}`} className="form-label">
            Hora Fim:
          </label>
          <input
            type="time"
            className="form-control input-md"
            id={`horaFim-${index}`}
            name="horaFim"
            readOnly
            value={horario[1] || ""}
          />
        </div>
      </div>
      <hr />
    </div>
  );
}

export default Turno;

import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import LayoultDefault from "../LayoultDefault";

const PrivateRoute = ({ children }) => {
  const { isLoggedIn } = useAuthContext();

  if (!isLoggedIn) return <Navigate to="/login" />;
  return <LayoultDefault>{children}</LayoultDefault>;
};

export default PrivateRoute;

import { useState } from "react";
import { apiClientFormData } from "../../service/api/PrestAPI";
import toast from "react-hot-toast";

const useUploadImage = () => {
  const [imageUrl, setImageUrl] = useState(null);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [error, setError] = useState(null);

  const uploadImage = async (image) => {
    if (!image) {
      const errorMessage = "Nenhuma imagem selecionada";
      setError(errorMessage);
      toast.error(errorMessage);
      return null;
    }

    setIsLoadingImage(true);
    setError(null);

    try {
      const formData = new FormData();
      formData.append("file", image);

      const result = await apiClientFormData.post("upload-image", formData);

      if (result.status === 200) {
        const data = result.data;
        setImageUrl(data.imageUrl);
        return data.imageUrl;
      } else {
        const errorMessage = result.data.error || "Erro ao carregar imagem";
        setError(errorMessage);
        toast.error(errorMessage);
      }
    } catch (error) {
      const errorMessage = error.message || "Erro ao adicionar imagem";
      setError(errorMessage);
    } finally {
      setIsLoadingImage(false);
    }

    return null;
  };

  return {
    imageUrl,
    isLoadingImage,
    error,
    uploadImage,
  };
};

export default useUploadImage;

import React, { useState } from "react";
import { Pencil, Trash3 } from "react-bootstrap-icons";
import { useBenefitsByStore } from "../../features/Desconto/hooks/useBenefitsByStore.js";
import { useAuthContext } from "../../context/AuthContext";
import { useCreateBenefit } from "../../features/Desconto/hooks/useCreateBenefit.js";
import { useUpdateBenefit } from "../../features/Desconto/hooks/useUpdateBenefit.js";
import { useDeleteBenefit } from "../../features/Desconto/hooks/useDeleteBenefit.js";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const tipo_desconto = {
  geral: "Geral",
};
const tipo_valor = {
  porcentagem: "Porcentagem",
  preco: "Preço",
};

const discountSchema = z
  .object({
    uid: z.string(),
    status: z.boolean(),
    cod_cupom: z
      .string()
      .min(1, "Código de desconto é obrigatório.")
      .max(20, "Código de desconto deve ter no máximo 20 caracteres."),
    data_inicio_cupom: z.string().refine((date) => !isNaN(Date.parse(date)), {
      message: "Data de início inválida.",
    }),
    data_fim_cupom: z.string().refine((date) => !isNaN(Date.parse(date)), {
      message: "Data de fim inválida.",
    }),
    tipo_cupom: z.enum(["geral"], {
      required_error: "Tipo de cupom é obrigatório.",
    }),
    vr_cupom: z
      .number({ invalid_type_error: "Valor do desconto deve ser um número." })
      .min(1, "Valor do desconto deve ser maior que 0."),
    tipo_vr_cupom: z.enum(["porcentagem"], {
      required_error: "Tipo de valor do desconto é obrigatório.",
    }),
    tipo_cupom_min: z.enum(["porcentagem", "preco"], {
      required_error: "Tipo de valor mínimo é obrigatório.",
    }),
    vr_cupom_min: z
      .number({ invalid_type_error: "Valor mínimo deve ser um número." })
      .min(0, "Valor mínimo deve ser maior ou igual a 0."),
    vr_max_aporte: z
      .number({ invalid_type_error: "Valor máximo deve ser um número." })
      .min(0, "Valor máximo deve ser maior ou igual a 0."),
  })
  .superRefine(({ data_inicio_cupom, data_fim_cupom }, ctx) => {
    if (new Date(data_fim_cupom) <= new Date(data_inicio_cupom)) {
      ctx.addIssue({
        path: ["data_fim_cupom"],
        message: "Data de fim deve ser posterior à data de início.",
      });
    }
  });

export default function Desconto() {
  const { estabelecimento, userData } = useAuthContext();
  const [benefitUpdateTrigger, setBenefitUpdateTrigger] = useState(0);
  const { benefits, loading } = useBenefitsByStore(
    estabelecimento.uid,
    benefitUpdateTrigger
  );
  const { createBenefit, loading: loadingCreate } = useCreateBenefit();
  const { updateBenefit, loading: loadingUpdate } = useUpdateBenefit();
  const { deleteBenefit, loading: loadingDelete } = useDeleteBenefit();

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(discountSchema),
    defaultValues: {
      uid: "",
      status: true,
      cod_cupom: "",
      data_inicio_cupom: "",
      data_fim_cupom: "",
      tipo_cupom: "geral",
      vr_cupom: 0,
      tipo_vr_cupom: "porcentagem",
      tipo_cupom_min: "porcentagem",
      vr_cupom_min: 0,
      vr_max_aporte: 0,
    },
  });
  const tipoCupom = watch("tipo_cupom");

  const onSubmit = async (data) => {
    const existingBenefit = benefits.find(
      (benefit) =>
        benefit.cod_cupom === data.cod_cupom && benefit.uid !== data.uid
    );

    if (existingBenefit) {
      toast.error("Código de desconto já em uso. Escolha um código diferente.");
      return;
    }
    const formData = {
      ...data,
      status: data.status ? 1 : 0,
      id_estabelecimento: estabelecimento.uid,
      id_usuario: userData.uid,
    };
    try {
      if (data.uid) {
        await updateBenefit(formData);
        setBenefitUpdateTrigger((prev) => prev + 1);
      } else {
        await createBenefit({
          ...formData,
          id_estabelecimento: estabelecimento.uid,
          id_usuario: userData.uid,
        });
        setBenefitUpdateTrigger((prev) => prev + 1);
      }
      toast.success("Desconto salvo com sucesso!");
      reset();
    } catch (error) {
      console.error("Erro ao salvar o Desconto:", error);
      toast.error("Erro ao salvar o Desconto. Tente novamente.");
    }
  };

  const handleEdit = (benefit) => {
    reset({
      ...benefit,
      data_inicio_cupom: benefit.data_inicio_cupom.slice(0, 16),
      status: benefit.status === 1,
    });
  };
  const handleCancel = () => {
    reset({
      uid: "",
      status: true,
      cod_cupom: "",
      data_inicio_cupom: "",
      data_fim_cupom: "",
      tipo_cupom: "geral",
      vr_cupom: 0,
      tipo_vr_cupom: "porcentagem",
      tipo_cupom_min: "porcentagem",
      vr_cupom_min: 0,
      vr_max_aporte: 0,
    });
    clearErrors();
  };

  const handleDelete = async (benefit) => {
    toast(
      (t) => (
        <div>
          <p>
            Tem certeza de que deseja excluir o Desconto{" "}
            <strong>{benefit.cod_cupom}</strong>?
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <button
              onClick={async () => {
                try {
                  await deleteBenefit(estabelecimento.uid, benefit.uid);
                  setBenefitUpdateTrigger((prev) => prev + 1);
                  toast.dismiss(t.id);
                  toast.success(
                    `Desconto '${benefit.cod_cupom}' excluído com sucesso!`
                  );
                } catch (error) {
                  console.error("Erro ao excluir o Desconto:", error);
                  toast.error("Erro ao excluir o Desconto. Tente novamente.");
                }
              }}
              style={{
                padding: "5px 10px",
                backgroundColor: "#FF5722",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Confirmar
            </button>
            <button
              onClick={() => toast.dismiss(t.id)}
              style={{
                padding: "5px 10px",
                backgroundColor: "#ccc",
                color: "black",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Cancelar
            </button>
          </div>
        </div>
      ),
      {
        duration: 5000,
        position: "top-center",
      }
    );
  };

  return (
    <div>
      <h2 className="fs-4">Cadastro de desconto geral</h2>
      <form className="row align-items-start" onSubmit={handleSubmit(onSubmit)}>
        <div className="col-lg-2">
          <label htmlFor="status" className="form-check-label">
            Status:
          </label>
          <div className="form-check">
            <input
              type="checkbox"
              id="status"
              {...register("status")}
              className="form-check-input mt-0"
              style={{ width: "30px", height: "30px" }}
            />
          </div>
          {errors.status && (
            <p className="text-danger">{errors.status.message}</p>
          )}
        </div>
        <div className="col-lg-6">
          <label className="form-label">Código Desconto</label>
          <input
            type="text"
            placeholder="Código Desconto"
            className="form-control rounded-3 bg-light"
            {...register("cod_cupom")}
          />
          {errors.cod_cupom && (
            <p className="text-danger">{errors.cod_cupom.message}</p>
          )}
        </div>
        <div className="col-lg-4">
          <label className="form-label">Tipo Desconto</label>
          <select className="form-select" {...register("tipo_cupom")}>
            <option value="geral">Geral</option>
          </select>
          {errors.tipo_cupom && (
            <p className="text-danger">{errors.tipo_cupom.message}</p>
          )}
        </div>
        <div className="col-lg-3">
          <label className="form-label">Data e hora inicial</label>
          <input
            type="datetime-local"
            placeholder="Data e hora inicial do desconto"
            className="form-control bg-light"
            {...register("data_inicio_cupom")}
          />
          {errors.data_inicio_cupom && (
            <p className="text-danger">{errors.data_inicio_cupom.message}</p>
          )}
        </div>
        <div className="col-lg-3">
          <label className="form-label">Data e hora final</label>
          <input
            type="datetime-local"
            placeholder="Data e hora final do desconto"
            className="form-control bg-light"
            {...register("data_fim_cupom")}
          />
          {errors.data_fim_cupom && (
            <p className="text-danger">{errors.data_fim_cupom.message}</p>
          )}
        </div>
        <div className="col-lg-4">
          <label className="form-label">Tipo valor cupom</label>
          <select className="form-select" {...register("tipo_vr_cupom")}>
            <option value="porcentagem">{tipo_valor.porcentagem}</option>
          </select>
          {errors.tipo_vr_cupom && (
            <p className="text-danger">{errors.tipo_vr_cupom.message}</p>
          )}
        </div>
        <div className="col-lg-2">
          <label className="form-label">Valor Desconto</label>
          <input
            type="number"
            placeholder="Porcentagem Desconto"
            className="form-control rounded-3 bg-light"
            {...register("vr_cupom", { valueAsNumber: true })}
          />
          {errors.vr_cupom && (
            <p className="text-danger">{errors.vr_cupom.message}</p>
          )}
        </div>
        <div className="col-12 mt-2 row">
          <div className="col-lg-2">
            <button
              type="submit"
              className="btn btn-vk w-100"
              disabled={loadingCreate || loadingUpdate}
            >
              {loadingCreate || loadingUpdate ? "Salvando..." : "Salvar"}
            </button>
          </div>
          <div className="col-lg-2">
            <button
              type="button"
              className="btn btn-secondary w-100"
              onClick={handleCancel}
              disabled={loadingCreate || loadingUpdate}
            >
              Cancelar
            </button>
          </div>
        </div>
      </form>

      {loading || loadingDelete ? (
        <div className="loader" id="loader">
          <span></span>
          <span></span>
          <span></span>
        </div>
      ) : (
        <div className="table-responsive mt-4">
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th scope="col">Código</th>
                <th scope="col">Valor do desconto</th>
                <th scope="col">Data e hora inicial</th>
                <th scope="col">Data e hora final</th>
                <th scope="col">Ação</th>
              </tr>
            </thead>
            <tbody>
              {benefits.length > 0 ? (
                benefits.map((benefit) => (
                  <tr key={benefit.uid}>
                    <td>{benefit.cod_cupom}</td>
                    <td>{benefit.vr_cupom}</td>
                    <td>
                      {new Date(benefit.data_inicio_cupom).toLocaleString(
                        "pt-BR",
                        {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        }
                      )}
                    </td>
                    <td>
                      {new Date(benefit.data_fim_cupom).toLocaleString(
                        "pt-BR",
                        {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        }
                      )}
                    </td>
                    <td className="d-flex justify-content-evenly">
                      <button
                        className="btn btn-outline-light"
                        title="Editar"
                        onClick={() => handleEdit(benefit)}
                      >
                        <Pencil color="#FF5722" />
                      </button>
                      <button
                        className="btn btn-outline-light"
                        title="Excluir"
                        onClick={() => handleDelete(benefit)}
                      >
                        <Trash3 color="#FF5722" />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">
                    Nenhum desconto encontrado.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

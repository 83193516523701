import React, { useState, useCallback, useMemo } from "react";
import * as PrestAPI from "../../service/api/PrestAPI";
import "./Pedidos.css";
import { toast } from "react-hot-toast";
import PedidoSelecionado from "./PedidoSelecionado";
import useMediaQuery from "../../utils/useMediaQuery";
import { useAuthContext } from "../../context/AuthContext";
import { useLoadPedidos } from "../../features/Pedidos/hooks/useLoadPedidos";
import renderStatus from "./components/RenderStatus";
import InfoCards from "./components/InfoCards";
import PedidoButton from "./components/PedidoButton";

const Pedidos = () => {
  const [pedido, setPedido] = useState(null);
  const [itens, setItens] = useState([]);
  const [modoPedido, setModoPedido] = useState("PENDENTE");
  const [subtotal, setSubtotal] = useState(0);
  const [expandedPedidoId, setExpandedPedidoId] = useState(null);
  const [loadingPedido, setLoadingPedido] = useState(false);
  const isAboveMediumScreens = useMediaQuery("(min-width:992px)");
  const { estabelecimento } = useAuthContext();
  const { pedidos, loadPedidos, isLoading } = useLoadPedidos(estabelecimento);

  const fetchPedido = useCallback(async (pedidoItem) => {
    setLoadingPedido(true);
    try {
      const value = await PrestAPI.get(
        "Pedidos",
        `id_pedido=${pedidoItem.id_pedido}`
      );
      setPedido(pedidoItem);
      setItens(value);
      setSubtotal(calculaSubtotal(value));
    } catch (error) {
      toast.error("Erro ao carregar o pedido.");
      console.error("Erro ao carregar pedido:", error);
    } finally {
      setLoadingPedido(false);
    }
  }, []);

  const calculaSubtotal = useCallback((itens) => {
    return itens.reduce((soma, item) => {
      const totalSubItens = item.vr_total_sub_itens || 0;
      const preco = (item.vr_unitario + totalSubItens) * item.quantidade;
      return soma + preco;
    }, 0);
  }, []);

  const updateStatusPedido = async (pedidoItem, statusPedido) => {
    try {
      pedidoItem.status_pedido = statusPedido;
      const response = await PrestAPI.update(
        "Pedido",
        `?id_pedido=${pedidoItem.id_pedido}`,
        pedidoItem
      );

      if ([200, 204].includes(response.status)) {
        await loadPedidos();
        setPedido(pedidoItem);
      } else {
        toast.error("Erro ao atualizar o status do pedido.");
      }
    } catch (error) {
      toast.error("Erro ao atualizar o status do pedido.");
      console.error("Erro ao atualizar o pedido:", error);
    }
  };

  const filterPedidos = useCallback(
    (status) => {
      if (!Array.isArray(pedidos)) return [];
      switch (status) {
        case "PENDENTE":
          return pedidos.filter((p) => p.status_pedido === "PENDENTE");
        case "ENVIADO":
          return pedidos.filter((p) => p.status_pedido === "ENVIADO");
        case "CONFIRMADO":
          return pedidos.filter((p) => p.status_pedido === "CONFIRMADO");
        case "FINALIZADO":
          return pedidos.filter((p) =>
            ["CANCELADO", "ENTREGUE"].includes(p.status_pedido)
          );
        default:
          return pedidos;
      }
    },
    [pedidos]
  );
  const pedidosFiltrados = filterPedidos(modoPedido);
  const pedidosAbertos = useMemo(
    () =>
      pedidos &&
      pedidos.filter(
        (p) => !["CANCELADO", "ENTREGUE"].includes(p.status_pedido)
      ),
    [pedidos]
  );

  const pedidosFinalizados = useMemo(
    () =>
      pedidos &&
      pedidos.filter((p) =>
        ["CANCELADO", "ENTREGUE"].includes(p.status_pedido)
      ),
    [pedidos]
  );

  const pedidosStats = useMemo(() => {
    const totalPedidos = Array.isArray(pedidos) ? pedidos.length : 0;
    const totalValor = Array.isArray(pedidos)
      ? pedidos.reduce((sum, p) => sum + (p.vr_pedido || 0), 0)
      : 0;
    const totalCancelados = Array.isArray(pedidos)
      ? pedidos.filter((p) => p.status_pedido === "CANCELADO").length
      : 0;
    const ticketMedio = totalPedidos > 0 ? totalValor / totalPedidos : 0;

    return {
      totalPedidos,
      totalValor,
      totalCancelados,
      ticketMedio,
    };
  }, [pedidos]);

  return (
    <div className="container-fluid page-pedido d-flex flex-column-reverse flex-md-column">
      <InfoCards pedidosStats={pedidosStats} />
      <div className="row">
        <div
          className="card col-lg-4 col-12 overflow-auto"
          style={{ height: "85vh", borderRadius: "3px 0 0 3px" }}
        >
          <div className="pedido-grid-button mb-4 card-header fw-bold">
            <PedidoButton
              label="Pendente"
              modo="PENDENTE"
              modoAtual={modoPedido}
              setModoPedido={setModoPedido}
              cor="#969595"
            />
            <PedidoButton
              label="Confirmados"
              modo="CONFIRMADO"
              modoAtual={modoPedido}
              setModoPedido={setModoPedido}
              cor="#4A90E2"
            />
            <PedidoButton
              label="Saiu para entrega"
              modo="ENVIADO"
              modoAtual={modoPedido}
              setModoPedido={setModoPedido}
              cor="#F5A623"
            />
            <PedidoButton
              label="Finalizado"
              modo="FINALIZADO"
              modoAtual={modoPedido}
              setModoPedido={setModoPedido}
              cor="#24814A"
            />
          </div>

          {pedidosFinalizados &&
            pedidosFiltrados.map((pedidoItem) => (
              <>
                <div
                  key={pedidoItem.id_pedido}
                  className={`card clickable pedido mt-3 fw-bold ${
                    pedido && pedidoItem.id_pedido === pedido.id_pedido
                      ? "pedidoSelecionado"
                      : ""
                  }`}
                  onClick={() => {
                    fetchPedido(pedidoItem);
                    setExpandedPedidoId(
                      expandedPedidoId === pedidoItem.id_pedido
                        ? null
                        : pedidoItem.id_pedido
                    );
                  }}
                  style={{
                    userSelect: "none",
                    backgroundColor:
                      pedidoItem.status_pedido === "PENDENTE"
                        ? "#727171FF"
                        : pedidoItem.status_pedido === "ENTREGUE"
                        ? "#04612BFF"
                        : pedidoItem.status_pedido === "CANCELADO"
                        ? "#BB2929FF"
                        : pedidoItem.status_pedido === "CONFIRMADO"
                        ? "#304E77FF"
                        : "#CE912FFF",
                  }}
                >
                  <div className="card-body overflow-hidden">
                    <div className="row text-light align-items-center justify-content-between justify-content-lg-center">
                      <div className="col fs-5">
                        {pedidoItem.id_pedido}
                      </div>
                      <div className="col fs-5" style={{ minWidth: "300px" }}>
                        {pedidoItem.nome_cliente}
                      </div>
                      <div className="col" style={{ minWidth: "200px" }}>
                        {renderStatus(pedidoItem.status_pedido)}
                      </div>
                    </div>
                  </div>
                </div>

                {expandedPedidoId === pedidoItem.id_pedido &&
                  !isAboveMediumScreens && (
                    <div className="card p-2 position-relative">
                      {loadingPedido ? (
                        <div className="loader" id="loader">
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      ) : (
                        <PedidoSelecionado
                          pedido={pedido}
                          subtotal={subtotal}
                          documentoHide={pedido.documento}
                          itens={itens}
                          renderStatus={renderStatus}
                          updateStatusPedido={updateStatusPedido}
                        />
                      )}
                    </div>
                  )}
              </>
            ))}
        </div>

        {pedido && isAboveMediumScreens && (
          <div
            className="card col-lg-8 col-12 pb-3 overflow-auto"
            style={{ height: "85vh", padding: "30px 80px" }}
          >
            {loadingPedido ? (
              <div className="loader" id="loader">
                <span></span>
                <span></span>
                <span></span>
              </div>
            ) : (
              <PedidoSelecionado
                pedido={pedido}
                subtotal={subtotal}
                documentoHide={pedido.documento}
                itens={itens}
                renderStatus={renderStatus}
                updateStatusPedido={updateStatusPedido}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Pedidos;

import { apiClient } from "../../service/api/PrestAPI";

const getBanners = async (storeUid) => {
  return await apiClient.get(`/store/${storeUid}/banner`);
};
const updateBanner = async (storeUid, bannerData) => {
  return await apiClient.put(`/store/${storeUid}/banner`, bannerData);
};
const deleteBanner = async (storeUid, id) => {
  return await apiClient.delete(`/store/${storeUid}/banner/${id}`);
};
const createBanner = async (storeUid, bannerData) => {
  return await apiClient.post(`/store/${storeUid}/banner`, bannerData);
};
export default { getBanners, createBanner, deleteBanner, updateBanner };

import React, { useState } from "react";
import toast from "react-hot-toast";
import { Pencil, Trash3 } from "react-bootstrap-icons";
import { useAuthContext } from "../../context/AuthContext";
import { useRegisterUsuario } from "../../features/usuario/hooks/useRegisterUsuario";
import { useGetUsuarios } from "../../features/usuario/hooks/useGetUsuarios";

const USER_ROLES_DISPLAY = {
  USER: "user",
  ADMIN: "admin",
  SUPER: "super",
  INTEGRACAO: "integração",
};

const USER_ROLES_API = {
  user: "USR",
  admin: "STR",
  super: "SUP",
  integração: "EXT",
};

const Usuarios = () => {
  const [isNewUser, setIsNewUser] = useState(false);
  const [editingUserId, setEditingUserId] = useState(null);
  const { userData, estabelecimento } = useAuthContext();
  const [formData, setFormData] = useState({
    nome: "",
    email: "",
    password: "",
    confirmPassword: "",
    role: USER_ROLES_DISPLAY.COMUM,
    code: "",
    status: 1,
    username: "",
    telefone: "",
    uid_estabelecimento: estabelecimento.uid,
  });

  const { addUsuario } = useRegisterUsuario();
  // const {
  //   fetchUsuarios,
  //   usuarios,
  //   loading: loadingUsuarios,
  // } = useGetUsuarios();
  const usuarios = [];

  const resetForm = () => {
    setFormData({
      nome: "",
      email: "",
      password: "",
      confirmPassword: "",
      role: USER_ROLES_DISPLAY.COMUM,
      code: "",
      status: 1,
      username: "",
      telefone: "",
    });
    setIsNewUser(false);
    setEditingUserId(null);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? (checked ? 1 : 0) : value,
    }));
  };

  const handleSaveOrEdit = async (e) => {
    e.preventDefault();

    if (formData.senha !== formData.confirmarSenha) {
      toast.error("As senhas não coincidem");
      return;
    }

    if (
      userData.role.toUpperCase() !== USER_ROLES_API.super &&
      (formData.role === USER_ROLES_DISPLAY.SUPER ||
        formData.role === USER_ROLES_DISPLAY.INTEGRACAO)
    ) {
      toast.error(
        "Você não tem permissão para criar ou editar usuários Super ou Integração"
      );
      return;
    }

    try {
      const dataToSave = {
        ...formData,
        role: USER_ROLES_API[formData.role].toUpperCase(),
      };

      if (editingUserId !== null) {
        alert("A funcionalidade de edição ainda não está integrada.");
      } else {
        await addUsuario(dataToSave);
        toast.success("Usuário adicionado com sucesso!");
      }
    } catch (error) {
      alert(
        "Erro ao salvar usuário: " + (error.message || "Erro desconhecido")
      );
    }

    resetForm();
  };

  const handleEdit = (index) => {
    // const userToEdit = usuarios[index];
    // setFormData({
    //   ...userToEdit,
    //   role: Object.keys(USER_ROLES_API).find(
    //     (key) => USER_ROLES_API[key] === userToEdit.role.toUpperCase()
    //   ),
    // });
    // setIsNewUser(true);
    // setEditingUserId(index);
  };

  const checkPermission = (role) => {
    const userRole = userData.role.toLowerCase();

    if (userRole === USER_ROLES_API.super) {
      return true;
    }
    if (
      userRole === USER_ROLES_API.admin &&
      (role === USER_ROLES_API.admin || role === USER_ROLES_API.comum)
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className="container">
      <h1 className="fs-3">Usuários</h1>
      <div>
        {["str", "sup"].includes(userData.role.toLowerCase()) && (
          <button className="btn btn-vk" onClick={() => setIsNewUser(true)}>
            Novo Usuário
          </button>
        )}
        {isNewUser && (
          <form className="mt-2 row" onSubmit={handleSaveOrEdit}>
            <div className="col-lg-6">
              <label htmlFor="nome" className="form-label">
                Nome
              </label>
              <input
                type="text"
                id="nome"
                name="nome"
                value={formData.nome}
                onChange={handleChange}
                className="form-control"
                required
              />
            </div>
            <div className="col-lg-6">
              <label htmlFor="username" className="form-label">
                Nome de Usuário (Email de login)
              </label>
              <input
                type="email"
                id="username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                className="form-control"
                required
              />
            </div>
            <div className="col-lg-6">
              <label htmlFor="telefone" className="form-label">
                Telefone
              </label>
              <input
                type="tel"
                id="telefone"
                name="telefone"
                value={formData.telefone}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="col-lg-6">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="form-control"
                required
              />
            </div>
            <div className="col-lg-6">
              <label htmlFor="senha" className="form-label">
                Senha
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="form-control"
                required
              />
            </div>
            <div className="col-lg-6">
              <label htmlFor="confirmarSenha" className="form-label">
                Confirme a senha
              </label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                className="form-control"
                required
              />
            </div>
            <div className="col-lg-6">
              <label htmlFor="role" className="form-label">
                Nível de Permissão
              </label>
              <select
                id="role"
                name="role"
                value={formData.role}
                onChange={handleChange}
                className="form-select"
                required
              >
                <option value={USER_ROLES_DISPLAY.COMUM}>User</option>
                <option value={USER_ROLES_DISPLAY.ADMIN}>Admin</option>
                {USER_ROLES_API.super === userData.role && (
                  <>
                    <option value={USER_ROLES_DISPLAY.SUPER}>Super</option>
                    <option value={USER_ROLES_DISPLAY.INTEGRACAO}>
                      Integração
                    </option>
                  </>
                )}
              </select>
            </div>
            {formData.role === USER_ROLES_DISPLAY.INTEGRACAO && (
              <div className="col-lg-6">
                <label htmlFor="code" className="form-label">
                  Código de Integração
                </label>
                <input
                  type="text"
                  id="code"
                  name="code"
                  value={formData.code}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            )}
            <div className="col-lg-6">
              <label htmlFor="status" className="form-label">
                Status
              </label>
              <input
                type="checkbox"
                id="status"
                name="status"
                checked={formData.status === 1}
                onChange={handleChange}
                className="form-check-input rounded rounded-circle form-control"
                style={{ width: "30px", height: "30px" }}
              />
            </div>
            <div className="mt-2">
              <button type="submit" className="btn btn-vk">
                {editingUserId !== null ? "Atualizar" : "Salvar"}
              </button>
              <button
                type="button"
                className="btn btn-secondary ms-2"
                onClick={resetForm}
              >
                Cancelar
              </button>
            </div>
          </form>
        )}

        <div className="row table-responsive mt-2">
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Nome de Usuário</th>
                <th>Email</th>
                <th>Telefone</th>
                <th>Permissão</th>
                <th>Status</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {usuarios.map((user, index) => (
                <tr key={index}>
                  <td>{user.nome}</td>
                  <td>{user.username}</td>
                  <td>{user.email}</td>
                  <td>{user.telefone}</td>
                  <td>
                    {Object.keys(USER_ROLES_API).find(
                      (key) => USER_ROLES_API[key] === user.role.toLowerCase()
                    )}
                  </td>
                  <td>{user.status ? "Ativo" : "Inativo"}</td>
                  <td className="d-flex justify-content-evenly">
                    {checkPermission(user.role) && (
                      <>
                        <button
                          className="btn btn-outline-light"
                          title="Editar"
                          onClick={() => handleEdit(index)}
                        >
                          <Pencil color="#FF5722" />
                        </button>
                        <button
                          className="btn btn-outline-light"
                          title="Excluir"
                          onClick={() =>
                            alert(
                              "Funcionalidade de exclusão ainda não implementada."
                            )
                          }
                        >
                          <Trash3 color="#FF5722" />
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Usuarios;

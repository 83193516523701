import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactLoading from "react-loading";
import { toast } from "react-hot-toast";
import useMediaQuery from "../../utils/useMediaQuery";
import { useAuthContext } from "../../context/AuthContext";
import backgroundLogin from "../../assets/imagens/backgroundLogin.png";
import backgroundLoginMrs from "../../assets/imagens/backgroundLoginMrs.jpg";
import backgroundLoginMobile from "../../assets/imagens/backgroundLoginMobile.jpg";
import { useNavigate, useLocation } from "react-router-dom";

const Login = ({ imagem }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [backgroundImage, setBackgroundImage] = useState({
    desktop: backgroundLogin,
    mobile: backgroundLoginMobile,
    hostname: "",
  });
  const { login, loading } = useAuthContext();
  const isAboveMediumScreens = useMediaQuery("(min-width:1599px)");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (window.location.href.includes("painel.mrstecno.com.br")) {
      setBackgroundImage({
        desktop: backgroundLoginMrs,
        mobile: backgroundLoginMrs,
        hostname: "mrs",
      });
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (username !== "" && password !== "") {
      const data = {
        username: username,
        password: password,
      };
      try {
        const success = await login(data);
        if (success) {
          navigate("/produto");
        } else {
          toast.error("Erro ao fazer login. Por favor, tente novamente.", {
            id: "login-error",
            duration: 2000,
          });
        }
      } catch (error) {
        toast.error("Erro ao fazer login. Por favor, tente novamente.", {
          id: "login-error",
          duration: 2000,
        });
      }
    } else {
      toast.error("Há campos vazios no formulário", {
        id: "login",
        duration: 1000,
      });
    }
  };

  return (
    <>
      <div
        className="position-fixed w-100 h-100 bg-light m-0 overflow-y-auto overflow-x-hidden"
        style={{
          backgroundImage: `url(${
            isAboveMediumScreens
              ? backgroundImage.desktop
              : backgroundImage.mobile
          })`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top-center",
          backgroundSize: "cover",
        }}
      >
        <div
          className="mx-auto p-2 me-lg-5 rounded-4 bg-white"
          style={{
            maxWidth: "600px",
            marginTop: "290px",
            width: "95%",
          }}
        >
          <form className="row m-0" onSubmit={handleSubmit}>
            <div className="col">
              <h3 className="fs-2 mt-4">Painel do Parceiro</h3>

              <h4 className="my-4">Gerencie sua loja aqui!</h4>
              <div className="col">
                <label className="form-label fs-4">Usuário(Email):</label>
                <input
                  name="cnpj"
                  type="text"
                  className="form-control form-control-lg p-2 fs-3 mt-2"
                  placeholder="Digite o usuário"
                  required
                  style={{ marginBottom: "16px" }}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="col ">
                <label className="form-label fs-4">Senha:</label>
                <input
                  name="senha"
                  type="password"
                  className="form-control form-control-lg mt-2 fs-3 p-2 mt-2"
                  placeholder="Digite sua senha"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <button
                className={`btn btn-lg w-100 ${
                  backgroundImage.hostname === "mrs" ? "btn-primary" : "btn-vk"
                } fs-3 my-4`}
                disabled={loading}
              >
                {loading ? (
                  <ReactLoading type="spin" color="white" className="m-auto" />
                ) : (
                  "Entrar"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

Login.propTypes = {
  imagem: PropTypes.string,
};

export default Login;

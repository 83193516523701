import { useState } from 'react';
import benefitsService from '../service';

export const useCreateBenefit = (token) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const createBenefit = async (data) => {
    try {
      setLoading(true);
      const response = await benefitsService.createBenefit(data);
      return response;
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { createBenefit, loading, error };
};

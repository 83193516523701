import { apiClient } from "../../service/api/PrestAPI";

const createTaxa = async (taxa) => {
  const res = await apiClient.post("Taxa", taxa);
  return res.json();
};

const updateTaxa = async ({ idTaxa, taxa }) => {
  const res = await apiClient.put(`Taxa?id_taxa=${idTaxa}`, taxa);
  return res;
};
const deleteTaxa = async (taxa) => {
  return await apiClient.delete("Taxa", {
    data: {
      id_taxa: taxa.id_taxa,
      id_estabelecimento: taxa.id_estabelecimento,
    },
  });
};

const getTaxas = async (id_estabelecimento) => {
  return await apiClient.get(`Taxa?id_estabelecimento=${id_estabelecimento}`);
};

export default {
  createTaxa,
  updateTaxa,
  getTaxas,
  deleteTaxa,
};

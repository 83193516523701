import React from "react";

export default function renderStatus(statusPedido) {
  const statusConfig = {
    CONFIRMADO: {
      text: "Confirmado",
      backgroundColor: "#4A90E2",
    },
    ENVIADO: {
      text: "Enviado",
      backgroundColor: "#F5A623",
    },
    ENTREGUE: {
      text: "Entregue",
      backgroundColor: "#24814A",
    },
    CANCELADO: {
      text: "Cancelado",
      backgroundColor: "#FA2323FF",
    },
    default: {
      text: "Pendente",
      backgroundColor: "#969595",
    },
  };

  const { border, text, backgroundColor } =
    statusConfig[statusPedido] || statusConfig.default;

  return (
    <div
      className="text-light text-center rounded p-2"
      style={{
        border,
        backgroundColor,
      }}
    >
      {text}
    </div>
  );
}

import React, { useState } from "react";
import "./Perfil.css";
import MobilePreview from "./MobilePreview";
import { useAuthContext } from "../../context/AuthContext";
import toast from "react-hot-toast";
import { validateImage } from "../../utils/validators";
import ColorSelect from "./ColorSelect";
import useUploadImage from "../../features/imagens/useUploadImage";
import useUpdateCurrentEstabelecimento from "../../features/Establecimentos/hooks/useUpdateCurrentEstabelecimento";

const Perfil = () => {
  const {
    estabelecimento,
    updateEstabelecimento: updateEstabelecimentoContext,
  } = useAuthContext();
  const { updateEstabelecimento, isUpdating } =
    useUpdateCurrentEstabelecimento();

  const { uploadImage, isLoadingImage } = useUploadImage();

  const [selectedColors, setSelectedColors] = useState({
    a: "#FF5722",
    b: "#FE8862",
    c: "#FFBAA4",
    d: "#FFE6DE",
    e: "#FFE6DE",
  });
  const [imagePreview, setImagePreview] = useState(
    estabelecimento.imagem || null
  );
  const [imageFile, setImageFile] = useState(null);

  const handleColorSelect = (colors) => {
    setSelectedColors(colors);
  };

  const handleImageChange = (event) => {
    const { files } = event.target;
    const image = files[0];
    const validationError = validateImage(image);

    if (validationError) {
      toast.error(validationError);
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
      setImageFile(image);
    };
    reader.readAsDataURL(image);
  };

  const handleSubmit = async () => {
    let uploadedImageUrl = imagePreview;

    if (imageFile) {
      uploadedImageUrl = await uploadImage(imageFile);
      if (!uploadedImageUrl) {
        toast.error("Erro ao fazer upload da imagem.");
        return;
      }
    }
    const estabelecimentoToUpdate = {
      ...estabelecimento,
      imagem: uploadedImageUrl,
    };
    try {
      await updateEstabelecimento(estabelecimentoToUpdate);
      updateEstabelecimentoContext(estabelecimento.uid);
      toast.success("Dados salvos com sucesso!");
    } catch (error) {
      toast.error("Erro ao salvar dados: " + error.message);
    }
  };

  return (
    <div className="container-fluid">
      <div className="container page-config">
        <div className="row justify-content-center">
          <section className="col-lg-3">
            <h3 className="text-center mb-4">Configurar minha loja</h3>
            <div className="circle-border overflow-hidden d-flex">
              {imagePreview ? (
                <img
                  src={imagePreview}
                  alt="Preview"
                  className="img-fluid rounded-circle d-flex w-100 h-auto"
                  style={{ objectFit: "contain" }}
                />
              ) : (
                <div className="input-img">
                  <label htmlFor="file">Upload Foto</label>
                  <input type="file" id="file" onChange={handleImageChange} />
                </div>
              )}
            </div>
            {imagePreview && (
              <button
                className="btn btn-outline-vk mt-2 w-100"
                onClick={() => setImagePreview(null)}
              >
                Mudar Imagem
              </button>
            )}
            <ColorSelect onColorSelect={handleColorSelect} />
            <button className="btn btn-vk mt-4 w-100">
              Visualizar cardápio ao vivo
            </button>

            <button
              className="btn btn-vk btn-lg w-100 mt-4"
              disabled={isLoadingImage || isUpdating}
              onClick={() => handleSubmit()}
            >
              {isLoadingImage || isUpdating ? "Salvando..." : "Salvar"}
            </button>
          </section>
          <section className="col-lg-4">
            <h3 className="text-center">Visualizador</h3>
            <MobilePreview
              colors={selectedColors}
              imageUrl={imagePreview}
              nomeFantasia={estabelecimento.nome_fantasia}
            />
          </section>
        </div>
      </div>
    </div>
  );
};

export default Perfil;

import React, { useState, Fragment, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";

import ModalComponentItem from "./ModalComponentItem";
import ModalCategoriaComplement from "./ModalCategoriaComplement";

function CategoriaComplementList({ categoria, getCatComplementos }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isNovo, setIsNovo] = useState(false);

  const showNovo = () => {
    setIsNovo(true);
  };

  const hideNovo = () => {
    setIsNovo(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
  };

  return (
    <Fragment>
      <div className="bg-light mt-4 mx-1 border border-light-subtle rounded-2 py-3 px-3 shadow-sm">
        <div className="row">
          <div className="col-md-7 mb-3">
            <h3>{categoria.nome_categoria}</h3>
          </div>
          <div className="col-md-2">
            <button
              className="btn btn-vk w-100"
              onClick={(e) => {
                showModal();
                e.stopPropagation();
              }}
            >
              Editar grupo
            </button>
          </div>
          <div className="col-md-3">
            <button
              className="btn btn-vk w-100"
              onClick={(e) => {
                showNovo();
                e.stopPropagation();
              }}
            >
              Adicionar Complemento
            </button>
          </div>
          <div className="col-md-8 text-muted">
            <h4>
              {!categoria.descricao_categoria
                ? "Grupo sem descrição"
                : categoria.descricao_categoria}
            </h4>
          </div>
          <div className="col-md-2">
            <h5>Qtde Min:{<b>{` ${categoria.qtde_min}`}</b>}</h5>
          </div>
          <div className="col-md-2">
            <h5>Qtde Max:{<b>{` ${categoria.qtde_maxima}`}</b>}</h5>
          </div>
        </div>
      </div>
      <Modal
        ariaHideApp={false}
        isOpen={isModalVisible}
        style={customStyles}
        contentLabel="Modal Complemento Item"
        onRequestClose={hideModal}
      >
        <ModalCategoriaComplement
          categoria={categoria}
          getCatComplementos={(categoria) => getCatComplementos(categoria)}
          showModal={showModal}
          hideModal={hideModal}
        />
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isNovo}
        style={customStyles}
        contentLabel="Modal Complemento Item"
        onRequestClose={hideNovo}
      >
        <ModalComponentItem
          modo={"novo"}
          ocultar={true}
          item={categoria}
          onClosed={() => {
            hideNovo();
            getCatComplementos(categoria);
          }}
          getCatComplementos={(categoria) => getCatComplementos(categoria)}
        />
      </Modal>
    </Fragment>
  );
}

export default CategoriaComplementList;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "5px",
    overflowY: "auto",
    maxWidth: "1100px",
    height: "fit-content",
    maxHeight: "100%",
  },
  overlay: {
    position: "fixed",
    backgroundColor: "rgba(30, 20, 0, 0.2)",
  },
};

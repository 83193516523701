import React, { useState, useEffect, useRef } from "react";

const resizeImageFile = (file, maxHeight) =>
  new Promise((resolve) => {
    const image = new Image();
    const reader = new FileReader();

    reader.onload = (event) => {
      image.src = event.target.result;
    };

    image.onload = () => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      if (image.height > maxHeight) {
        image.width *= maxHeight / image.height;
        image.height = maxHeight;
      }

      canvas.width = image.width;
      canvas.height = image.height;
      context.drawImage(image, 0, 0, image.width, image.height);

      canvas.toBlob((blob) => {
        const resizedFile = new File([blob], file.name, { type: file.type });
        resolve(resizedFile);
      }, file.type);
    };

    reader.readAsDataURL(file);
  });

function ImageInput({ className, name, defaultValue, maxHeight, onImageChange }) {
  const [value, setValue] = useState(defaultValue || "");
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.match(/^image\//)) {
      resizeImageFile(file, maxHeight).then((resizedFile) => {
        const objectUrl = URL.createObjectURL(resizedFile);
        setValue(objectUrl);

        if (onImageChange) {
          onImageChange(resizedFile);
        }
      });
    } else {
      setValue("");
    }
  };

  const handleFormReset = () => {
    setValue(defaultValue || "");
  };

  useEffect(() => {
    const fileInput = fileInputRef.current;
    if (fileInput && fileInput.form) {
      fileInput.form.addEventListener("reset", handleFormReset);
    }

    return () => {
      if (fileInput && fileInput.form) {
        fileInput.form.removeEventListener("reset", handleFormReset);
      }
    };
  }, [defaultValue]);

  const style = {
    position: "relative",
    backgroundImage: `url("${value ? value : "./assets/icons/produto.svg"}")`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    border: "2px solid #ddd",
    width: "170px",
    height: "170px",
    cursor: "pointer",
  };

  return (
    <div className={className} style={style}>
      <input type="hidden" name={name} value={value} />
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        aria-label="Upload image"
        onChange={handleFileChange}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          opacity: 0,
          cursor: "pointer",
        }}
      />
    </div>
  );
}
export default ImageInput;

import { useState } from "react";
import service from "../service";

export const useDeleteBanner = (storeUid) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const deleteBanner = async (id) => {
    setLoading(true);
    try {
      await service.deleteBanner(storeUid, id);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return { deleteBanner, loading, error };
};

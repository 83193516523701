import { apiClient } from "../../service/api/PrestAPI";

const getEstabelecimento = async (uid) => {
  return await apiClient.get(`estabelecimento/${uid}`);
};
const getEstabelecimentos = async () => {
  return await apiClient.get("/stores");
};
const getSegmentos = async () => {
  return await apiClient.get("/Categorias");
};
// const updateCurrentEstabelecimento = async (estabelecimento) => {
//   return await apiClient.put(`Estabelecimento`, estabelecimento);
// };
const updateCurrentEstabelecimento = async (estabelecimento) => {
  return await apiClient.put(
    `/store/${estabelecimento.uid}/edit`,
    estabelecimento
  );
};
const updateEstabelecimento = async (estabelecimento) => {
  return await apiClient.put(
    `Estabelecimento?idEstab=${estabelecimento.id_estabelecimento}`,
    estabelecimento
  );
};

const createEstabelecimento = async (estabelecimento) => {
  return await apiClient.post("/Estabelecimento", estabelecimento);
};

export default {
  getEstabelecimento,
  getEstabelecimentos,
  getSegmentos,
  updateCurrentEstabelecimento,
  updateEstabelecimento,
  createEstabelecimento,
};
